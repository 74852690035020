import { CSSProperties, memo, MutableRefObject, ReactNode, useCallback, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { List, WindowScroller } from 'react-virtualized';

import { GalleryViewBox } from './GalleryViewBox';
import { GalleryItem, GallerySection } from './types';
import { calculateListRowHeight } from './utils/calculateListRowHeight';
import { sectionsToRows } from './utils/sectionsToRows';

const itemRenderer = ({ box, render, index }: GalleryItem, isVisible: boolean) => (
  <GalleryViewBox key={index} box={box} render={render} index={index} isVisible={isVisible} />
);

export interface GalleryViewProps {
  sections: GallerySection[];
  width: number;
  rowRenderer?: (params: {
    index: number;
    style: CSSProperties;
    row: GalleryItem[];
    isVisible: boolean;
    itemRenderer: (item: GalleryItem, isVisible: boolean) => void;
  }) => ReactNode;
  style?: CSSProperties;
  scrollElementRef: MutableRefObject<HTMLDivElement | null>;
}

/**
 * This component displays Gallery view
 */
export const GalleryView = memo(({ scrollElementRef, sections, width, style, rowRenderer }: GalleryViewProps) => {
  const rows = sectionsToRows(sections);

  const listRef = useRef<List | null>(null);

  useEffect(() => {
    listRef.current?.recomputeRowHeights();
  }, [rows]);

  const getRowHeight = useCallback(({ index }: { index: number }) => calculateListRowHeight({ index, rows }), [rows]);

  return scrollElementRef.current ? (
    <WindowScroller scrollElement={scrollElementRef.current}>
      {({ scrollTop, isScrolling }) => (
        <List
          height={typeof window !== 'undefined' ? window.innerHeight : 0}
          width={width}
          isScrolling={isScrolling}
          scrollTop={scrollTop}
          rowCount={rows.length}
          tabIndex={-1}
          autoHeight
          ref={listRef}
          rowHeight={getRowHeight}
          rowRenderer={({ index, style, isVisible }) => {
            const row = rows[index];

            if (!row) {
              return <div key={index} style={{ background: 'white', width: 100, height: 100 }} />;
            }

            return rowRenderer ? (
              rowRenderer({ index, style, isVisible, itemRenderer, row })
            ) : (
              <div style={style} key={index}>
                {row.map((item) => itemRenderer(item, isVisible))}
              </div>
            );
          }}
          style={style}
          overscanRowCount={2}
        />
      )}
    </WindowScroller>
  ) : null;
}, isEqual);

GalleryView.displayName = 'GalleryView';
