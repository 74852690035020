import { useBreakpointsContext } from '@air/provider-media-query';
import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef, forwardRef, MouseEventHandler, useCallback, useEffect, useRef } from 'react';
import { flushSync } from 'react-dom';

export const GALLERY_CARD_SELECTION_BUTTON = 'GALLERY_CARD_SELECTION_BUTTON';

export interface AssetCardThumbnailProps extends ComponentPropsWithoutRef<'button'> {
  preSelectItem: () => void;
  confirmItemSelection: () => void;
  isSelectable: boolean;
  isHovering: boolean;
  isHighlighted: boolean;
  onClick?: () => void;
}

/**
 * This button handles the gallery card being clicked and whether or not it will trigger an onClick
 * or the selection event
 */
export const GalleryCardSelectionButton = forwardRef<HTMLButtonElement, AssetCardThumbnailProps>(
  (
    { onClick, preSelectItem, confirmItemSelection, isSelectable, isHovering, isHighlighted, className, ...rest },
    forwardedRef,
  ) => {
    const { isAboveMediumScreen } = useBreakpointsContext();

    const isMouseDownRef = useRef(false);

    /**
     * We can not use the onClick event to trigger the selection event because onMouseUp may happen outside of the element
     * E.g. when user clicks down and scrolls the page - in that case, selection would be in a weird state, as if mouse button was still pressed
     */
    const onMouseUp = useCallback(() => {
      isMouseDownRef.current = false;
      flushSync(() => {
        if (isAboveMediumScreen && isSelectable) {
          confirmItemSelection();
        }
      });
    }, [confirmItemSelection, isAboveMediumScreen, isSelectable]);

    useEffect(() => {
      if (isMouseDownRef.current) {
        window.addEventListener('mouseup', onMouseUp, { once: true });
      }
      return () => {
        window.removeEventListener('mouseup', onMouseUp);
      };
    }, [onMouseUp]);

    const onMouseDown = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        flushSync(() => {
          if (event.button === 0) {
            if (!isAboveMediumScreen || !isSelectable) return;
            isMouseDownRef.current = true;
            preSelectItem?.();
            window.addEventListener('mouseup', onMouseUp, { once: true });
          }
        });
      },
      [isAboveMediumScreen, isSelectable, onMouseUp, preSelectItem],
    );

    return (
      <button
        {...rest}
        data-testid={GALLERY_CARD_SELECTION_BUTTON}
        onMouseDown={onMouseDown}
        onClick={!isAboveMediumScreen || !isSelectable ? onClick : undefined}
        className="absolute inset-0"
        ref={forwardedRef}
        style={{
          /**
           * Without this, the user can't select the card and drag on Firefox
           * @see https://air-labs-team.slack.com/archives/CSU3B8B3R/p1689598810694499
           */
          minWidth: '-moz-available',
        }}
      >
        <span
          /**
           * We can't apply this absolute position to the button because Firefox doesn't handle it
           * @see https://air-labs-team.slack.com/archives/CSU3B8B3R/p1689187866653019
           */
          className={tailwindMerge(
            'absolute inset-[-6px] cursor-default rounded border-2 border-transparent',
            isHovering && 'bg-grey-4',
            isHighlighted && 'inset-[-7px] border-macaw-300',
            className,
          )}
        >
          <span className="sr-only">Click to select</span>
        </span>
      </button>
    );
  },
);

GalleryCardSelectionButton.displayName = 'GalleryCardSelectionButton';
