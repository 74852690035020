import Link from 'next/link';
import { memo } from 'react';

export const ReCaptchaTermsInfo = memo(() => {
  return (
    <p className="text-12 text-pigeon-500">
      This site is protected by reCAPTCHA and the Google{' '}
      <Link href="https://policies.google.com/privacy" target="_blank" className="text-pigeon-500 underline">
        Privacy Policy
      </Link>{' '}
      and{' '}
      <Link href="https://policies.google.com/terms" target="_blank" className="text-pigeon-500 underline">
        Terms of Service
      </Link>{' '}
      apply.
    </p>
  );
});

ReCaptchaTermsInfo.displayName = 'ReCaptchaTermsInfo';
