import { ShortUrl } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';

import { getPublicCustomFieldsKey } from '~/constants/react-query-keys';

export const useGetPublicCustomFields = (enabled = true) => {
  const { shortId } = useShortIdContext();
  const key = getPublicCustomFieldsKey(shortId);

  return useQuery({
    queryKey: key,
    queryFn: () => ShortUrl.listCustomFields(shortId!, { limit: 100 }),
    enabled,
  });
};
