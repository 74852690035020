import { TrackSelectionTrigger, useTrackSelectedItems } from '@air/analytics';
import { shiftSelectHighlightedItemsSelector } from '@air/redux-selected-items';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getTrackSelectedItemsCountsAndType } from '~/analytics/utils';
import { selectShiftSelectedHighlightedGalleryItemsAction } from '~/store/selectedItems/actions';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { useAirStore } from '~/utils/ReduxUtils';

export function useSelectShiftSelectedHighlightedItems() {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { trackSelectedItems } = useTrackSelectedItems();

  const selectShiftSelectedHighlightedItems = useCallback(
    (nextAnchor: SelectableGalleryItem, trigger: TrackSelectionTrigger) => {
      const nextSelections = shiftSelectHighlightedItemsSelector(store.getState());
      const trackingParams = getTrackSelectedItemsCountsAndType(nextSelections);
      trackSelectedItems({
        ...trackingParams,
        trigger,
      });

      dispatch(selectShiftSelectedHighlightedGalleryItemsAction({ nextAnchor }));
    },
    [dispatch, store, trackSelectedItems],
  );

  return { selectShiftSelectedHighlightedItems };
}
