import { GallerySection, GalleryView, GalleryViewProps } from '@air/component-gallery-view';
import { PropsWithChildren, RefObject } from 'react';

import { MouseSelectionContainer } from '~/components/MouseSelectionContainer';
import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';
import { useGallerySelectableItemsPositions } from '~/hooks/useGallerySelectableItemsPositions';
import { UseMouseSelectionParams } from '~/hooks/useMouseSelection';

export interface SelectableGalleryViewProps extends GalleryViewProps {
  containerRef: RefObject<HTMLDivElement>;
  sections: GallerySection<GalleryMetadata>[];
  minSelectionTopPosition?: UseMouseSelectionParams['minSelectionTopPosition'];
}

export const SelectableGalleryView = ({
  containerRef,
  sections,
  scrollElementRef,
  minSelectionTopPosition = 110,
  ...galleryViewProps
}: PropsWithChildren<SelectableGalleryViewProps>) => {
  const { itemsPositions } = useGallerySelectableItemsPositions({
    sections,
  });

  return (
    <MouseSelectionContainer
      scrollElementRef={scrollElementRef}
      containerRef={containerRef}
      itemsPositions={itemsPositions}
      minSelectionTopPosition={minSelectionTopPosition}
    >
      <GalleryView scrollElementRef={scrollElementRef} sections={sections} {...galleryViewProps} />
    </MouseSelectionContainer>
  );
};
