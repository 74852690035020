import { FormikField } from '@air/formik-field';
import { Eye, EyeClosed } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Input, InputProps } from '@air/primitive-input';
import { useToggle } from 'react-use';

interface PasswordInputProps extends Partial<Pick<InputProps, 'disabled' | 'size'>> {
  label?: string;
  name?: string;
  testId?: string;
  className?: string;
  description?: string;
}

const PasswordInput = ({ className, testId, label, name, disabled, size, description }: PasswordInputProps) => {
  const fieldID = 'Password';
  const [isValueVisible, togglePasswordVisibility] = useToggle(false);

  return (
    <div className="relative w-full">
      <FormikField
        id={name || 'password'}
        className={className}
        label={label || 'Password *'}
        description={description}
        component={
          <Input
            className="w-full pr-9"
            size={size}
            disabled={disabled}
            data-testid={testId}
            autoComplete="new-password"
            type={isValueVisible ? 'text' : 'password'}
          />
        }
        name={name || 'password'}
      />
      <IconButton
        appearance="ghost"
        color="grey"
        size="medium"
        className="absolute right-1.5 top-1/2 mt-[11px] -translate-y-1/2"
        label={isValueVisible ? 'Hide password' : 'Show password'}
        icon={isValueVisible ? Eye : EyeClosed}
        onClick={togglePasswordVisibility}
        aria-controls={fieldID}
        aria-expanded={isValueVisible}
      />
    </div>
  );
};

export default PasswordInput;
