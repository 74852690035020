import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export default function useIsShiftPressed() {
  const [pressed, setPressed] = useState(false);

  const cancelPressed = useCallback(() => setPressed(false), [setPressed]);

  useHotkeys(
    '*',
    (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        if (event.type === 'keydown') {
          setPressed(true);
        } else if (event.type === 'keyup') {
          setPressed(false);
        }
      }
    },
    {
      keydown: true,
      keyup: true,
    },
  );

  // Cancel pressed when browser loses focus, e.g. it is minimzed
  useEffect(() => {
    window.addEventListener('blur', cancelPressed);
    return () => {
      window.removeEventListener('blur', cancelPressed);
    };
  }, [cancelPressed]);

  return pressed;
}
