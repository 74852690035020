import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { ComponentProps, forwardRef, ReactNode } from 'react';

export const cardAction = tailwindVariants({
  base: 'pointer-events-auto flex h-6 min-w-[24px] items-center items-center justify-center justify-center gap-1 rounded px-1',
  variants: {
    color: {
      light:
        'bg-pigeon-100 text-pigeon-700 hover:bg-pigeon-200 hover:text-pigeon-700 focus-visible:bg-pigeon-200 focus-visible:text-pigeon-700 active:bg-pigeon-200 active:text-pigeon-700',
      dark: 'bg-black/70 text-white hover:bg-black hover:text-white focus-visible:bg-black focus-visible:text-white active:bg-black active:text-white',
    },
  },
  defaultVariants: {
    color: 'dark',
  },
});

type CardIconVariants = VariantProps<typeof cardAction>;

export type CardActionProps = CardIconVariants &
  ComponentProps<'button'> & {
    icon: ReactNode;
    text?: string | number;
  };

export const CardAction = forwardRef<HTMLButtonElement, CardActionProps>(
  ({ icon, text, color, className, ...rest }, ref) => {
    return (
      <button {...rest} ref={ref} className={cardAction({ color, class: className })}>
        {icon}
        {!!text && <span className="text-12 uppercase text-inherit">{text}</span>}
      </button>
    );
  },
);

CardAction.displayName = 'CardAction  ';
