import { useCallback } from 'react';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getReferralCookies } from '~/utils/getReferralCookies';

export const useReferral = () => {
  const trackReferral = useCallback(async ({ accountId }: { accountId: string }) => {
    const referralId = getReferralCookies();

    if (referralId) {
      try {
        await fetch(`/api/referral?accountId=${accountId}&referralId=${referralId}`);
      } catch (error) {
        reportErrorToBugsnag({ error, context: 'Error logging referralId', metadata: { Data: referralId } });
      }
    }
  }, []);

  return { trackReferral };
};
