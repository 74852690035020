import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AnonymousAccountTrackingLocation } from '../types';
import META from './useTrackLoggedInToAnonymousAccount.meta';

export interface TrackLoggedInToAnonymousAccountParams {
  share_url: string;
  object_id: string;
  object_type: 'clip' | 'board';
  location: AnonymousAccountTrackingLocation;
}

export const useTrackLoggedInToAnonymousAccount = () => {
  const { track } = useAnalyticsUtilities();

  const trackLoggedInToAnonymousAccount = useCallback(
    (params: TrackLoggedInToAnonymousAccountParams) => {
      track(META.name, params);
    },
    [track],
  );

  return {
    trackLoggedInToAnonymousAccount,
  };
};
