import type { JustifiedBox, RowItem } from '../types';

/**
 * Creates an array of rows from the boxes output from JustifiedGrid
 * @example
 * const rows = boxesToRows(boxes, clips)
 * @param {array} [boxes=[]] - The boxes output from the JustifiedGrid library
 * @returns {RowItem<Data>[][]} An array of row items
 */
type BaseType = object;

export function boxesToRows(boxes: JustifiedBox[]): RowItem<BaseType>[][] {
  return boxes.reduce((curr, box, i) => {
    const lastRow = curr[curr.length - 1];
    const rowItem: RowItem<BaseType> = {
      box,
      index: i,
    };

    if (lastRow && lastRow[0].box.top === box.top) {
      lastRow.push(rowItem);
    } else {
      curr.push([rowItem]);
    }

    return curr;
  }, [] as RowItem<BaseType>[][]);
}
