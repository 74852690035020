import { JustifiedBox } from '@air/component-gallery-view';
import { useMemo } from 'react';

export interface UseCSSGridLikeLayoutProps {
  height: number;
  minWidth: number;
  itemCount: number;
  containerWidth: number;
  maxWidth?: number;
  containerHorizontalPadding?: number;
  itemHorizontalSpacing?: number;
  squared?: boolean;
}

export default function useCSSGridLikeLayout({
  height,
  minWidth,
  itemCount,
  containerWidth,
  maxWidth = Number.POSITIVE_INFINITY,
  containerHorizontalPadding = 12,
  itemHorizontalSpacing = 16,
  squared = false,
}: UseCSSGridLikeLayoutProps) {
  const boxes: JustifiedBox[] = useMemo(() => {
    const allowedWidth = containerWidth - containerHorizontalPadding * 2;
    // we want at minimum, one item per row
    const itemsPerRow = Math.max(Math.floor(allowedWidth / minWidth), 1);
    const spacingPerItem = (itemHorizontalSpacing * (itemsPerRow - 1)) / itemsPerRow;
    const itemWidth = Math.min(maxWidth, allowedWidth / itemsPerRow - spacingPerItem);
    const aspectRatio = itemWidth / height;
    const calculatedHeight = squared ? itemWidth : height;
    let rowIndex = 0;
    let leftSpacingOffset = 0;

    const result: JustifiedBox[] = [];
    for (let i = 0; i < itemCount; i++) {
      const indexInRow = i % itemsPerRow;
      result.push({
        aspectRatio,
        top: rowIndex * calculatedHeight,
        width: itemWidth,
        height: calculatedHeight,
        left: containerHorizontalPadding + leftSpacingOffset + indexInRow * itemWidth,
      });

      leftSpacingOffset += itemHorizontalSpacing;

      if (indexInRow + 1 === itemsPerRow) {
        rowIndex++;
        leftSpacingOffset = 0;
      }
    }
    return result;
  }, [
    containerWidth,
    containerHorizontalPadding,
    minWidth,
    itemHorizontalSpacing,
    height,
    squared,
    itemCount,
    maxWidth,
  ]);

  return { boxes };
}
