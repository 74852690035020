import { AnonymousAccountTrackingLocation } from '@air/analytics';
import { useTrackCreatedAnonymousAccount } from '@air/analytics/src/events/useTrackCreatedAnonymousAccount';
import { useTrackLoggedInToAnonymousAccount } from '@air/analytics/src/events/useTrackLoggedInToAnonymousAccount';
import { useTrackViewedAnonymousAccountCreationForm } from '@air/analytics/src/events/useTrackViewedAnonymousAccountCreationForm';
import { useToasts } from '@air/provider-toast';
import { memo, useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMount } from 'react-use';
import invariant from 'tiny-invariant';

import { AnonymousSignUpForm, AnonymousSignUpFormProps } from '~/components/AnonymousSignUpForm/AnonymousSignUpForm';
import { ReCaptchaTermsInfo } from '~/components/AnonymousSignUpForm/ReCaptchaTermsInfo';
import { useAnonymousSignUp } from '~/components/PublicLinkAnonymousSignUpModal/hooks/useAnonymousSignUp';
import { convertUnknownToError, reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { isCypressRun } from '~/utils/PathUtils';

export interface AnonymousSignUpFormWrapperProps extends Omit<AnonymousSignUpFormProps, 'onSubmitEmail' | 'onSignUp'> {
  onLoggedIn: () => void;
  trackLocation: AnonymousAccountTrackingLocation;
  shortId: string;
  objectId: string;
  objectType: 'clip' | 'board';
}

export const AnonymousSignUpFormWrapper = memo(
  ({ onLoggedIn, objectType, objectId, shortId, trackLocation, ...props }: AnonymousSignUpFormWrapperProps) => {
    const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
    const { showToast } = useToasts();

    const { loginWithEmail, signupWithEmailAndName } = useAnonymousSignUp();

    const { trackLoggedInToAnonymousAccount } = useTrackLoggedInToAnonymousAccount();
    const { trackCreatedAnonymousAccount } = useTrackCreatedAnonymousAccount();
    const { trackViewedAnonymousAccountCreationForm } = useTrackViewedAnonymousAccountCreationForm();

    const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

    invariant(siteKey, 'RECAPTCHA_SITE_KEY is not defined');

    useMount(() => {
      trackViewedAnonymousAccountCreationForm({
        share_url: shortId,
        object_id: objectId,
        object_type: objectType,
        location: trackLocation,
      });
    });

    const getRecaptchaToken = useCallback(async () => {
      if (isCypressRun()) {
        // Return dummy token for cypress, it will be ignored anyway
        return 'test-token';
      }
      // ReCaptcha must be reset before getting a new value,
      // One token can be used only one time to verify a user, so everytime we have to get a new value
      reCaptchaRef.current?.reset();

      try {
        const token = await reCaptchaRef.current?.executeAsync();
        if (!token) {
          throw new Error('Can not get ReCaptcha token');
        }
        return token;
      } catch (error) {
        reportErrorToBugsnag({
          error: convertUnknownToError(error),
          context: 'Can not get ReCaptcha token',
        });

        throw error;
      }
    }, []);

    const onSubmitEmail = useCallback<AnonymousSignUpFormProps['onSubmitEmail']>(
      async (email) => {
        try {
          const recaptchaToken = await getRecaptchaToken();
          if (!recaptchaToken) {
            throw new Error('Can not get ReCaptcha token');
          }

          const account = await loginWithEmail({ email, recaptchaToken });
          if (account) {
            trackLoggedInToAnonymousAccount({
              share_url: shortId,
              object_id: objectId,
              object_type: objectType,
              location: trackLocation,
            });
            onLoggedIn();
          }
          return account;
        } catch (error) {
          showToast('Unknown error occurred', {
            type: 'assertive',
            withCloseButton: true,
          });
          throw error;
        }
      },
      [
        getRecaptchaToken,
        loginWithEmail,
        objectId,
        objectType,
        onLoggedIn,
        shortId,
        showToast,
        trackLocation,
        trackLoggedInToAnonymousAccount,
      ],
    );

    const onSignUp = useCallback<AnonymousSignUpFormProps['onSignUp']>(
      async (email, name) => {
        try {
          const recaptchaToken = await getRecaptchaToken();
          if (!recaptchaToken) {
            throw new Error('Can not get ReCaptcha token');
          }

          await signupWithEmailAndName({ email, name, recaptchaToken });
          trackCreatedAnonymousAccount({
            share_url: shortId,
            object_id: objectId,
            object_type: objectType,
            location: trackLocation,
          });
          onLoggedIn();
        } catch (_error) {
          showToast('Unknown error occurred', {
            type: 'assertive',
            withCloseButton: true,
          });
        }
      },
      [
        getRecaptchaToken,
        objectId,
        objectType,
        onLoggedIn,
        shortId,
        showToast,
        signupWithEmailAndName,
        trackCreatedAnonymousAccount,
        trackLocation,
      ],
    );

    return (
      <AnonymousSignUpForm {...props} onSubmitEmail={onSubmitEmail} onSignUp={onSignUp}>
        <div>
          {!!siteKey && <ReCAPTCHA ref={reCaptchaRef} size="invisible" sitekey={siteKey} />}
          <ReCaptchaTermsInfo />
        </div>
      </AnonymousSignUpForm>
    );
  },
);

AnonymousSignUpFormWrapper.displayName = 'AnonymousSignUpFormWrapper';
