import { useTrackViewedLogInForm } from '@air/analytics';
import { Account } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalProps } from '@air/primitive-modal';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { tailwindMerge } from '@air/tailwind-variants';
import { getIsSAMLEnforced } from '@air/utils-auth';
import { useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';
import Router from 'next/router';
import { useCallback } from 'react';
import { useMount } from 'react-use';

import { LoginForm } from '~/components/Auth/LoginForm';
import { DividerText } from '~/components/DividerText';
import { AirModalLogoHeader } from '~/components/Modals/AirModalLogoHeader';
import { SamlSSOLoginModal } from '~/components/Modals/SamlSSOLoginModal';
import { SignupModal, SignupModalProps } from '~/components/Modals/SignupModal/SignUpModal';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { SSOButtons } from '~/components/SSOButtons';
import { Routes } from '~/constants/routes';
import { getAccountKey } from '~/swr-hooks/account/useAccount';
import { useLogin } from '~/swr-hooks/auth/useLogin';

export interface LoginModalProps {
  onSuccess?: (account: Account) => void;
  ssoCallbackData?: SSOCallbackData;
  accountCreationSource: SignupModalProps['accountCreationSource'];
  /** Called when user dismisses modal */
  onCancel?: () => void;
  overlayClassName?: ModalProps['overlayClassName'];
}

export const LoginModal = ({
  onSuccess = noop,
  ssoCallbackData,
  onClose,
  onCancel,
  accountCreationSource,
  overlayClassName,
}: AirModalProps<LoginModalProps>) => {
  const [showSignUpModal] = useAirModal(SignupModal);
  const [showSAMLSSOModal] = useAirModal(SamlSSOLoginModal);
  const [showLoginModal] = useAirModal(LoginModal);
  const queryClient = useQueryClient();
  const { showToast } = useToasts();
  const { trackViewedLogInForm } = useTrackViewedLogInForm();

  useMount(() => {
    Router.prefetch(Routes.auth.login);
    trackViewedLogInForm();
  });

  const { loginUser, showLoginError } = useLogin();

  const openSignupModal = () => {
    onClose();
    showSignUpModal({ onSuccess, ssoCallbackData, accountCreationSource, onCancel, overlayClassName });
  };

  const openSAMLSSOModal = () => {
    onClose();

    // add a short delay to avoid blinking when one modal is closing, and the second one appears
    setTimeout(
      () =>
        showSAMLSSOModal({
          onShowLoginClick: () => {
            showLoginModal({ onSuccess, ssoCallbackData, accountCreationSource });
          },
          ssoCallbackData,
          customState: {
            creationSource: accountCreationSource,
          },
        }),
      200,
    );
  };

  const onDismiss = useCallback(() => {
    onClose();
    onCancel?.();
  }, [onCancel, onClose]);

  return (
    <Modal
      data-testid="LOGIN_MODAL"
      // hide y scrollbar to avoid double scroll
      overlayClassName={tailwindMerge(overlayClassName, 'overflow-y-hidden')}
      className="max-h-[80vh] w-[414px] overflow-y-auto p-8"
      isOpen
      onDismiss={onDismiss}
    >
      <AirModalLogoHeader onClose={onDismiss} />
      <h1 className="mb-4 text-center text-20 font-semibold text-grey-12">Log in</h1>
      {ssoCallbackData && (
        <SSOButtons
          verbText="Log in"
          ssoCallbackData={ssoCallbackData}
          onContinueWithSamlClick={openSAMLSSOModal}
          customState={{
            creationSource: accountCreationSource,
          }}
        />
      )}
      <DividerText />
      <div className="flex flex-col gap-7">
        <LoginForm
          loginUser={async (username: string, password: string) => {
            const isSAMLEnforced = await getIsSAMLEnforced(username);
            if (isSAMLEnforced) {
              showToast('You must sign in with SAML SSO for this account', {
                timeBeforeAutoDismiss: undefined,
                type: 'assertive',
                withCloseButton: true,
              });
              return;
            }
            const account = await loginUser(username, password);
            await queryClient.invalidateQueries({ queryKey: getAccountKey() });
            onClose();
            onSuccess(account);
          }}
          onError={(username, error) => {
            // this error comes from Cognito
            if ((error as any).code === 'PasswordResetRequiredException') {
              Router.push(Routes.auth.login);
            } else {
              showLoginError(username, error);
            }
          }}
        >
          {({ isSubmitting, isValid }) => (
            <Button
              type="submit"
              className="mt-12 w-full"
              size="large"
              appearance="filled"
              color="blue"
              isLoading={isSubmitting}
              disabled={!isValid || isSubmitting}
            >
              Log in
            </Button>
          )}
        </LoginForm>
        <div className="flex flex-col items-center justify-center">
          <p className="text-center text-14 text-pigeon-600"> Don&apos;t have an account?</p>
          <button
            data-testid="OPEN_SIGNUP_MODAL_BUTTON"
            className="text-14 font-medium text-jay-500 hover:underline"
            onClick={openSignupModal}
          >
            Sign up
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
