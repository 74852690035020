import { ErrorMessage, Field } from 'formik';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { SIGN_UP_TERMS_INPUT } from '~/constants/testIDs';

/**
 * MailChimp is requiring us to add this to our sign up flow in order to send
 * out marketing campaign. You'll notice that this isn't using any existing component
 * and everything is inline. This is a quick temporary solution until we have a
 * proper checkbox component that lives within Zephyr. (Sorry Kyle)
 */

export const TermsCheckbox = () => {
  return (
    <label className="mb-6 mt-12 flex text-12 text-pigeon-500" htmlFor="terms" data-testid={SIGN_UP_TERMS_INPUT}>
      <div className="mr-2 mt-1 shrink-0">
        <Field id="terms" type="checkbox" name="terms" required />
      </div>
      <div>
        By checking this box, you agree to Air&apos;s <Link href={Routes.marketing.terms}>Terms of Service</Link> ,{' '}
        <Link href={Routes.marketing.privacy}>Privacy Policy</Link>, and occasional emails regarding product and service
        updates. You can unsubscribe at any time.
        <ErrorMessage name="terms">{(msg) => <div className="text-12 text-flamingo-600">{msg}</div>}</ErrorMessage>
      </div>
    </label>
  );
};
