import { TrackSelectionTrigger } from '@air/analytics';
import {
  isHighlightedSelector,
  itemIsPreSelectedSelector,
  itemIsSelectedSelector,
  resetSelectedItemsAction,
  showShiftSelectHighlightsSelector,
} from '@air/redux-selected-items';
import { FocusEvent, MouseEvent as ReactMouseEvent, useCallback, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';

import { useFocusable } from '~/hooks/useFocusable';
import { useMultiselectItem, UseMultiselectItemsType } from '~/hooks/useMultiselectItem';
import { useModifiersKeysPressedContext } from '~/providers/ModifiersKeysPressedProvider';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

import { useSelectShiftSelectedHighlightedItems } from './useSelectShiftSelectedHighlightedItems';

// NotSelectableUpload is here to satisfy TypeScript - TableView uses the same layout for SelectedItem and Uploads, and this hook is used there
export interface UseSelectableCardProps<T extends SelectableGalleryItem> {
  item: T;
  isSelectable: boolean;
  onClick?: (item: T['item']) => void;
}

export interface UseSelectableCardReturnType {
  handleItemClick: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  isHovering: boolean;
  isSelected: boolean;
  isHighlighted: boolean;
  handleHover: () => void;
  handleUnhover: () => void;
  onBlur: (e: FocusEvent<HTMLDivElement, Element>) => void;
  onFocus: (e: FocusEvent<HTMLDivElement, Element>) => void;
  confirmItemSelection: () => void;
  handleItemDoubleClick: () => void;
  itemRef: React.RefObject<HTMLDivElement>;
  preSelectItem: () => void;
}

export function useSelectableCard<T extends SelectableGalleryItem, E extends HTMLDivElement>({
  item: { id, type, item: data },
  item,
  isSelectable,
  onClick,
}: UseSelectableCardProps<T>): UseSelectableCardReturnType {
  const dispatch = useDispatch();
  const store = useAirStore();
  const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, id));
  const isHighlighted = useAirSelector((st) => isHighlightedSelector(st, item.id));
  const isPreSelected = useAirSelector((st) => itemIsPreSelectedSelector(st, id));
  const { selectShiftSelectedHighlightedItems } = useSelectShiftSelectedHighlightedItems();
  const { isCmdPressed, isShiftPressed } = useModifiersKeysPressedContext();

  const { isHovering, handleHover, handleUnhover, handleSelect, handleUnselect, handlePreSelect } = useMultiselectItem(
    id,
    type,
    data,
  );

  const handleShiftEnterPress = useCallback(() => {
    selectShiftSelectedHighlightedItems(item, 'shift+enter');
  }, [item, selectShiftSelectedHighlightedItems]);

  const select = useCallback(
    (params: Parameters<UseMultiselectItemsType['handleSelect']>[0]) => {
      if (isSelectable) {
        handleSelect(params);
      }
    },
    [isSelectable, handleSelect],
  );

  const preSelect = useCallback(
    (params: Parameters<UseMultiselectItemsType['handleSelect']>[0]) => {
      if (isSelectable) {
        handlePreSelect(params);
      }
    },
    [isSelectable, handlePreSelect],
  );

  const handleCmdEnterPress = useCallback(
    (e?: KeyboardEvent) => {
      e?.preventDefault();
      select({ trigger: 'meta+enter' });
    },
    [select],
  );

  const handleEnterPress = useCallback(() => {
    const trigger = 'enter';

    if (isSelected) {
      handleUnselect({ trigger });
    } else {
      dispatch(resetSelectedItemsAction());
      select({ trigger });
    }
  }, [dispatch, handleUnselect, isSelected, select]);

  // this is here to check if current element is active (so if user does not click e.g. ellipsis menu on card)
  // useHotKeys returns ref and checks it itself, but that handles all key events for defined keys, so in this case buttons did not receive enter event
  // this has been fixed by adding { enabled: isHovering }, but then ellipsis menu inside a card did not received enter event
  const itemRef = useRef<E>(null);

  useHotkeys(
    'meta+enter, ctrl+enter, shift+enter, enter',
    (event) => {
      if (itemRef.current === document.activeElement?.parentElement) {
        // Prevent multiple calls when user holds key down
        if (!event.repeat && event.key === 'Enter') {
          // @ts-ignore
          if (event.ctrlKey || event.metaKey) {
            handleCmdEnterPress(event);
          } else if (event.shiftKey) {
            handleShiftEnterPress();
          } else {
            handleEnterPress();
          }
        }
      }
    },
    {
      enabled: isHovering,
      preventDefault: true,
    },
    [handleCmdEnterPress, handleShiftEnterPress, handleEnterPress],
  );

  const confirmItemSelection = useCallback(() => {
    if (isPreSelected) {
      let trigger: TrackSelectionTrigger = 'click';
      let reset = true;
      if (isCmdPressed()) {
        trigger = 'meta+click';
        reset = false;
      }
      if (isShiftPressed()) {
        trigger = 'shift+click';
        reset = false;
      }

      select({ trigger, reset });
    }
  }, [isCmdPressed, isPreSelected, isShiftPressed, select]);

  const preSelectItem = useCallback(() => {
    let trigger: TrackSelectionTrigger = 'click';
    let reset = true;
    if (isCmdPressed()) {
      trigger = 'meta+click';
      if (isSelected) return handleUnselect({ trigger });
      reset = false;
    }
    if (isShiftPressed()) {
      trigger = 'shift+click';
      reset = false;
    }
    preSelect({ trigger, reset });
  }, [handleUnselect, isCmdPressed, isSelected, isShiftPressed, preSelect]);

  const handleItemClick = useCallback(
    (e?: ReactMouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      const multiselectHighlightIsShowing = showShiftSelectHighlightsSelector(store.getState());
      if (!multiselectHighlightIsShowing) {
        data && onClick?.(data);
      } else {
        e && select({ trigger: 'click' });
      }
    },
    [data, store, onClick, select],
  );

  const handleItemDoubleClick = () => {
    handleUnselect({ trigger: 'double-click' });
    handleItemClick();
  };
  const [onFocus, onBlur] = useFocusable(handleHover, handleUnhover);

  return {
    handleItemClick,
    handleHover,
    handleUnhover,
    isHovering,
    onFocus,
    onBlur,
    confirmItemSelection,
    handleItemDoubleClick,
    isSelected,
    itemRef,
    preSelectItem,
    isHighlighted,
  };
}
