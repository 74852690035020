import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AnonymousAccountTrackingLocation } from '../types';
import META from './useTrackCreatedAnonymousAccount.meta';

export interface TrackCreatedAnonymousAccountParams {
  share_url: string;
  object_id: string;
  object_type: 'clip' | 'board';
  location: AnonymousAccountTrackingLocation;
}

export const useTrackCreatedAnonymousAccount = () => {
  const { track } = useAnalyticsUtilities();

  const trackCreatedAnonymousAccount = useCallback(
    (params: TrackCreatedAnonymousAccountParams) => {
      track(META.name, params);
    },
    [track],
  );

  return {
    trackCreatedAnonymousAccount,
  };
};
