import { Button } from '@air/primitive-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo, PropsWithChildren } from 'react';

interface AnonymousSignUpFooterProps {
  className?: string;
  onFullLoginClick: () => void;
  onCancelClick?: () => void;
}

export const AnonymousSignUpFooter = memo(
  ({ children, className, onFullLoginClick, onCancelClick }: PropsWithChildren<AnonymousSignUpFooterProps>) => {
    return (
      <div className={tailwindMerge('flex items-center justify-between', className)}>
        <p className="text-12 text-pigeon-500">
          Already on Air?{' '}
          <button
            data-testid="ANONYMOUS_SIGNUP_FORM_FULL_LOGIN_BUTTON"
            type="button"
            className="font-semibold text-jay-600 hover:underline"
            onClick={onFullLoginClick}
          >
            Log In
          </button>
        </p>
        <div className="flex gap-3">
          {onCancelClick && (
            <Button onClick={onCancelClick} appearance="ghost" color="grey" size="large">
              Cancel
            </Button>
          )}
          {children}
        </div>
      </div>
    );
  },
);

AnonymousSignUpFooter.displayName = 'AnonymousSignUpFooter';
