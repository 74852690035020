import { Button } from '@air/primitive-button';
import { tailwindMerge } from '@air/tailwind-variants';
import classNames from 'classnames';
import { type ComponentPropsWithoutRef, type ReactNode } from 'react';

import { RotatingDowntick } from '~/components/RotatingDowntick';
import { LIST_SECTION_TITLE } from '~/constants/testIDs';

export interface ListSectionHeaderProps extends ComponentPropsWithoutRef<'div'> {
  headerTitle: ReactNode;
  /** click handler */
  onClick?: () => void;
  /** true if section is collapsed */
  collapsed?: boolean;
  showDowntick?: 'always' | 'hover-only';
}

export const ListSectionHeader = ({
  className,
  collapsed = false,
  headerTitle,
  onClick,
  children,
  showDowntick = 'hover-only',
  ...rest
}: ListSectionHeaderProps) => {
  return (
    <div className={tailwindMerge('relative flex items-end justify-between pb-4', className)} {...rest}>
      <Button
        appearance="ghost"
        color="grey"
        className={classNames('group/list-section-header -ml-2', !!onClick ? 'cursor-pointer' : 'cursor-default')}
        size="small"
        onClick={onClick}
        data-testid={LIST_SECTION_TITLE}
      >
        <div className="relative bg-transparent text-12 font-bold uppercase text-grey-10">{headerTitle}</div>

        {!!onClick && (
          <div
            className={classNames(
              'pointer-events-none ml-1 group-hover/list-section-header:opacity-100',
              showDowntick === 'hover-only' ? 'opacity-0' : 'opacity-100',
            )}
          >
            <RotatingDowntick rotate={collapsed} />
          </div>
        )}
      </Button>
      {children}
    </div>
  );
};
