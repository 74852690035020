import { ComponentProps } from 'react';

/**
 * Text that has a leading and trailing horizonal line surrounding it.
 */
export const DividerText = ({ children = 'or' }: ComponentProps<'div'>) => (
  <div className="mb-6 mt-5 h-auto overflow-hidden text-center text-12 font-semibold text-grey-10" aria-hidden="true">
    <div className="relative right-4 ml-[-50%] inline-block h-px w-1/2 bg-grey-4 align-middle" />

    {children}

    <div className="relative left-4 mr-[-50%] inline-block h-px w-1/2 bg-grey-4 align-middle" />
  </div>
);
