/**
 * We round the image dimensions to the next 100 to ensure that we're not generating
 * a new image size for every pixel difference in the image dimensions.
 */
export const getRoundedImgixDimensions = ({ height, width }: { width: number; height: number }) => {
  const aspectRatio = width / height;
  const roundedHeight = Math.ceil(height / 100) * 100;
  return {
    height: roundedHeight,
    width: Math.ceil(roundedHeight * aspectRatio),
  };
};
