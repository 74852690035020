import { calculateListRowHeight, GallerySection, sectionsToRows } from '@air/component-gallery-view';
import { useMemo } from 'react';

import { GalleryItemType } from '~/components/Gallery/types';
import { MouseSelectableItem } from '~/components/MouseSelectionContainer';
import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';
import { SelectableGalleryItem } from '~/store/selectedItems/types';

interface UseGallerySelectableItemsPositionsParams {
  sections: GallerySection<GalleryMetadata>[];
}

const defaultGetData = (): GalleryMetadata => ({
  isSelectable: false,
  type: GalleryItemType.loader,
  itemId: '',
});

export function useGallerySelectableItemsPositions({ sections }: UseGallerySelectableItemsPositionsParams) {
  const itemsPositions = useMemo(() => {
    const rows = sectionsToRows<GalleryMetadata>(sections);
    let lastTop = 0; // This should be top offset of the first element of the list
    return rows.reduce((acc, row, index) => {
      lastTop = index === 0 ? lastTop : lastTop + calculateListRowHeight({ index: index - 1, rows });
      const newItems = row.reduce((selectableItems, rowData) => {
        const { index, box, getData = defaultGetData } = rowData;

        const { isSelectable, type, itemId } = getData(index);
        if (isSelectable) {
          selectableItems.push({
            box: {
              ...box,
              top: lastTop,
            },
            selectableItem: {
              id: itemId,
              type,
            } as SelectableGalleryItem,
          });
        }

        return selectableItems;
      }, [] as MouseSelectableItem[]);

      acc.push(...newItems);

      return acc;
    }, [] as MouseSelectableItem[]);
  }, [sections]);

  return {
    itemsPositions,
  };
}
