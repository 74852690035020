import { config } from '@air/api';

import {
  configureAPIPackageForAnonymousSignUp,
  isAPIConfiguredForAnonymousSignUp,
  restoreAPIPackageConfiguration,
} from '~/utils/ConfigureAmplify';

/**
 * This method configures cognito to use anonymous user pool and calls callback using this configuration
 * If callback throws an error, it restores original configuration
 * If callback succeeds, anonymous user pool is set as current config
 */
export const callOnAnonymousUserPool = async <T>(callback: () => Promise<T>) => {
  // if API is already configured as anonymous, call callback
  if (isAPIConfiguredForAnonymousSignUp()) {
    return callback();
  }

  // else, configure API as anonymous, call callback and restore original configuration
  const savedAPIConfig = config.getAuthConfiguration();
  try {
    configureAPIPackageForAnonymousSignUp();
    return await callback();
  } catch (error) {
    if (savedAPIConfig) {
      restoreAPIPackageConfiguration(savedAPIConfig);
    }
    throw error;
  }
};
