import { Tooltip } from '@air/primitive-tooltip';
import { tailwindMerge } from '@air/tailwind-variants';
import classNames from 'classnames';
import { ComponentProps, MouseEventHandler } from 'react';

import { CustomField, CustomFieldProps } from '~/components/CustomField';
import { getFieldsToRenderAndOverflowCount, GetFieldsToRenderAndOverflowCountProps } from '~/utils/CustomFields';

export type CustomFieldListWithOverflowProps = Pick<GetFieldsToRenderAndOverflowCountProps, 'customFields'> &
  Pick<ComponentProps<'div'>, 'className'> & {
    allowedWidth: number;
    hasDarkBackground?: boolean;
    shouldShowTooltip?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    'data-testid'?: string;
    size?: CustomFieldProps['size'];
  };

export const CustomFieldListWithOverflow = ({
  allowedWidth,
  customFields,
  className,
  hasDarkBackground = false,
  shouldShowTooltip = false,
  onClick,
  size = 'medium',
  ['data-testid']: dataTestId,
}: CustomFieldListWithOverflowProps) => {
  const { fieldsToRender, hiddenFieldsCount } = getFieldsToRenderAndOverflowCount({
    allowedWidth,
    customFields,
    widthPerChar: 10,
  });

  if (!fieldsToRender.length) return null;

  return (
    <button
      className={tailwindMerge(
        'mt-0.5 flex w-full items-center justify-start rounded',
        !!onClick ? 'cursor-pointer hover:bg-grey-5' : 'cursor-default hover:bg-transparent',
        className,
      )}
      disabled={!onClick}
      onClick={onClick}
      data-testid={dataTestId}
      onMouseDown={(event) => event.preventDefault()}
    >
      <div className="flex items-center gap-1">
        {fieldsToRender.map((field) => {
          if (field.value) {
            if (shouldShowTooltip) {
              return (
                <Tooltip label={field.fieldName} key={field.id}>
                  <CustomField field={field} size={size} />
                </Tooltip>
              );
            }

            return <CustomField field={field} key={field.id} size={size} />;
          }

          return null;
        })}
      </div>

      {hiddenFieldsCount > 0 && (
        <div className="ml-1.5 shrink-0">
          <div className={classNames('text-12 font-medium', hasDarkBackground ? 'text-white' : 'text-black')}>
            +{hiddenFieldsCount}
          </div>
        </div>
      )}
    </button>
  );
};
