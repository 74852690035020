import { Box } from '@air/react-drag-to-select';
import { memo, PropsWithChildren, RefObject } from 'react';

import { MultiselectKeys } from '~/components/MultiselectKeys';
import { useMouseSelection, UseMouseSelectionParams } from '~/hooks/useMouseSelection';
import { SelectableGalleryItem } from '~/store/selectedItems/types';

export interface MouseSelectableItem {
  box: Box;
  selectableItem: SelectableGalleryItem | null;
}

export interface MouseSelectionContainerProps {
  containerRef: RefObject<HTMLElement>;
  itemsPositions: MouseSelectableItem[];
  scrollElementRef: UseMouseSelectionParams['scrollElementRef'];
  minSelectionTopPosition?: UseMouseSelectionParams['minSelectionTopPosition'];
}

export const MouseSelectionContainer = memo(
  ({
    children,
    containerRef,
    itemsPositions,
    scrollElementRef,
    minSelectionTopPosition,
  }: PropsWithChildren<MouseSelectionContainerProps>) => {
    const { DragSelection } = useMouseSelection({
      containerRef,
      rowsPositions: itemsPositions,
      scrollElementRef,
      minSelectionTopPosition,
    });

    return (
      <>
        <DragSelection />
        {children}
        <MultiselectKeys />
      </>
    );
  },
);

MouseSelectionContainer.displayName = 'MouseSelectionContainer';
