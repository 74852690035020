import { Auth } from '@air/api';
import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { getRegistrationEmailValidationSchema } from '@air/utils-auth';
import { Formik } from 'formik';
import { useState } from 'react';
import { InferType } from 'yup';

import Form from '~/components/Form';
import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { SIGN_UP_CONTINUE_BUTTON, SIGN_UP_EMAIL_INPUT } from '~/constants/testIDs';
import { pushWithExistingQuery } from '~/utils/PathUtils';

const _SignUpValidationSchema = getRegistrationEmailValidationSchema();

export type SignUpUserChekerValues = InferType<typeof _SignUpValidationSchema>;

export interface EmailSignUpCheckerProps {
  defaultEmail?: string;
  onSuccess: (values: SignUpUserChekerValues) => void;
  onError: (error: unknown) => void;
  disabled?: boolean;
}

export const EmailSignUpChecker = ({ defaultEmail, disabled, onSuccess, onError }: EmailSignUpCheckerProps) => {
  const validationSchema = getRegistrationEmailValidationSchema(defaultEmail);
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={validationSchema.cast({})}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          setSubmitting(true);
          const { exists } = await Auth.isUserRegistered(values.email);
          if (exists) {
            pushWithExistingQuery({
              path: Routes.auth.login,
              newQuery: { [QueryParamNames.existingEmail]: values.email },
            });
          } else {
            onSuccess(values);
          }
        } catch (error) {
          if (onError) {
            onError(error);
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>
        <FormikField
          id="email"
          className="mb-6"
          component={
            <Input
              type="email"
              placeholder="olivia@companyname.com"
              autoComplete="username"
              size="large"
              data-testid={SIGN_UP_EMAIL_INPUT}
              disabled={disabled}
            />
          }
          label="Work email *"
          name="email"
        />
        <Button
          className="w-full"
          appearance="filled"
          size="large"
          color="blue"
          type="submit"
          data-testid={SIGN_UP_CONTINUE_BUTTON}
          disabled={disabled}
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </Form>
    </Formik>
  );
};
