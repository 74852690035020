import { ActivatedAccount } from '@air/analytics';
import { Account } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { useToasts } from '@air/provider-toast';
import { getIsSAMLEnforced } from '@air/utils-auth';
import { noop } from 'lodash';
import { memo } from 'react';

import { EmailSignUpForm } from '~/components/Auth/EmailSignUpForm';
import { useSignUpWithEmail } from '~/swr-hooks/auth/useSignUpWithEmail';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface SignUpModalEmailFormProps {
  email: string;
  onSuccess: (account: Account) => void;
  accountCreationSource: ActivatedAccount['creationSource'];
  onBackClick: () => void;
}

const formID = 'sign-up-with-email-modal-form';

export const SignUpModalUserDetailsForm = memo(
  ({ onSuccess, accountCreationSource, email, onBackClick }: SignUpModalEmailFormProps) => {
    const { showToast } = useToasts();
    const { signUpWithEmail } = useSignUpWithEmail();

    return (
      <EmailSignUpForm
        initialValues={{ terms: true }}
        defaultEmail={email}
        onSubmit={async (user) => {
          const isSamlEnforced = await getIsSAMLEnforced(user.email);

          if (isSamlEnforced) {
            showToast('You must sign in with SAML SSO for this account', {
              timeBeforeAutoDismiss: undefined,
              type: 'assertive',
              withCloseButton: true,
            });
          } else {
            await signUpWithEmail({
              user,
              setFormError: (error) =>
                showToast(error, { timeBeforeAutoDismiss: null, type: 'assertive', withCloseButton: true }),
              onRegistrationFailure: noop,
              onRegistrationSuccess: (account) => {
                onSuccess(account);
              },
              onUnhandledException: (error) => {
                reportErrorToBugsnag({
                  error,
                  context: 'Error during signup',
                  metadata: { Data: { email: user.email } },
                });
              },
              creationSource: accountCreationSource,
            });
          }
        }}
        termsCheckbox={null}
        formID={formID}
      >
        {({ isSubmitting, isValid }) => (
          <div className="mt-12 flex flex-col gap-2">
            <Button
              type="submit"
              isLoading={isSubmitting}
              size="large"
              color="blue"
              disabled={!isValid || isSubmitting}
            >
              Sign up with email
            </Button>
            <Button
              type="button"
              size="large"
              appearance="ghost"
              color="grey"
              disabled={isSubmitting}
              onClick={onBackClick}
            >
              Back
            </Button>
          </div>
        )}
      </EmailSignUpForm>
    );
  },
);

SignUpModalUserDetailsForm.displayName = 'SignUpModalEmailForm';
