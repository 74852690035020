import Image from 'next/image';
import { memo } from 'react';

export interface AnonymousSignUpModalHeaderProps {
  imageSrc: string;
  alt: string;
}

export const AnonymousSignUpModalHeader = memo(({ imageSrc, alt }: AnonymousSignUpModalHeaderProps) => (
  <Image priority={true} width={496} height={167} className="rounded-t-md" alt={alt} src={imageSrc} />
));

AnonymousSignUpModalHeader.displayName = 'SharedLinkAnonymousSignInHeader';
