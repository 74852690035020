import { ActivatedAccount, useTrackViewedSignUpForm } from '@air/analytics';
import { Account } from '@air/api/types';
import { Modal, ModalProps } from '@air/primitive-modal';
import { useAirModal } from '@air/provider-modal';
import { tailwindMerge } from '@air/tailwind-variants';
import { noop } from 'lodash';
import { MouseEvent as ReactMouseEvent, useCallback, useEffect } from 'react';

import { AirModalLogoHeader } from '~/components/Modals/AirModalLogoHeader';
import { LoginModal } from '~/components/Modals/LoginModal/LoginModal';
import { SignUpModalFooter } from '~/components/Modals/SignupModal/SignUpModalFooter';
import { SignUpModalForm } from '~/components/Modals/SignupModal/SignUpModalForm';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';

export interface SignupModalProps {
  onSuccess?: (account: Account) => void;
  /**
   * onSuccess is NOT called when using 3rd party signin clients. The redirect does not provide an onSuccess callback
   * instead, ssoCallbackData allows you to set a url to be redirected to once signup and/or login is successful
   * action would need to be hard-coded in PublicSSOCallback which follows up on the action string.
   */
  ssoCallbackData?: SSOCallbackData;
  accountCreationSource: ActivatedAccount['creationSource'];
  /** Called when user dismisses modal */
  onCancel?: () => void;
  overlayClassName?: ModalProps['overlayClassName'];
}

export const SignupModal = ({
  onSuccess = noop,
  ssoCallbackData,
  onClose,
  onCancel,
  accountCreationSource,
  overlayClassName,
}: AirModalProps<SignupModalProps>) => {
  const [showLoginModal] = useAirModal(LoginModal);
  const { trackViewedSignUpForm } = useTrackViewedSignUpForm();

  useEffect(() => {
    trackViewedSignUpForm();
  }, [trackViewedSignUpForm]);

  const onLoginClick = (event?: ReactMouseEvent) => {
    event?.preventDefault();
    onClose();
    showLoginModal({ onSuccess, ssoCallbackData, accountCreationSource, onCancel });
  };

  const onDismiss = useCallback(() => {
    onClose();
    onCancel?.();
  }, [onCancel, onClose]);

  return (
    <Modal
      // hide y scrollbar to avoid double scroll
      overlayClassName={tailwindMerge(overlayClassName, 'overflow-y-hidden')}
      className="max-h-[80vh] w-[414px] overflow-y-auto p-8"
      isOpen
      onDismiss={onDismiss}
      data-testid="SIGNUP_MODAL"
    >
      <AirModalLogoHeader onClose={onDismiss} />
      <h1 className="mb-4 text-center text-20 font-semibold text-pigeon-700">Sign Up</h1>

      <div className="flex flex-col gap-7">
        <SignUpModalForm
          closeModal={onClose}
          ssoCallbackData={ssoCallbackData}
          onSuccess={(account) => {
            onSuccess(account);
            onClose();
          }}
          accountCreationSource={accountCreationSource}
          onLoginClick={onLoginClick}
        />
        <SignUpModalFooter onLoginClick={onLoginClick} />
      </div>
    </Modal>
  );
};
