import { GallerySectionAddition } from '@air/component-gallery-view';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useCallback, useMemo, useState } from 'react';

import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';
import { ListSectionHeader } from '~/components/Shared/ListSectionHeader';
import {
  WKSPC_GALLERY_FIRST_SECTION_GAP_DESKTOP,
  WKSPC_GALLERY_FIRST_SECTION_GAP_MOBILE,
} from '~/constants/WorkspaceSpacing';

export const SECTIONS_DISTANCE = 64;

export const useGallerySectionHeader = ({
  isFirstSection,
  containerWidth,
  title,
  containerPadding,
  onSectionCollapse,
}: {
  isFirstSection: boolean;
  containerWidth: number;
  title: string;
  containerPadding: number;
  onSectionCollapse?: (isCollapsed: boolean) => void;
}): { isHeaderCollapsed: boolean; header: GallerySectionAddition<GalleryMetadata> } => {
  const [isHeaderCollapsed, setHeaderCollapsed] = useState(false);
  const { isAboveMediumScreen } = useBreakpointsContext();

  const headerBox = useMemo(
    () => ({
      aspectRatio: 0,
      left: containerPadding,
      top: 0,
      width: containerWidth - containerPadding,
      height: isFirstSection
        ? !isAboveMediumScreen
          ? WKSPC_GALLERY_FIRST_SECTION_GAP_MOBILE
          : WKSPC_GALLERY_FIRST_SECTION_GAP_DESKTOP
        : SECTIONS_DISTANCE + 16,
    }),
    [containerPadding, containerWidth, isFirstSection, isAboveMediumScreen],
  );

  const renderHeader = useCallback(() => {
    return (
      <ListSectionHeader
        headerTitle={title}
        collapsed={isHeaderCollapsed}
        onClick={() => {
          setHeaderCollapsed((collapsed) => {
            onSectionCollapse?.(!collapsed);
            return !collapsed;
          });
        }}
      />
    );
  }, [isHeaderCollapsed, onSectionCollapse, title]);

  return {
    isHeaderCollapsed,
    header: {
      box: headerBox,
      render: renderHeader,
    },
  };
};
