import { noop } from 'lodash';
import { ComponentProps, MouseEventHandler, SyntheticEvent } from 'react';

import {
  DropdownMenuWithActionSheet,
  DropdownMenuWithActionSheetProps,
} from '~/components/Menus/DropdownMenuWithActionSheet';

export type CardEllipsisMenuProps = {
  actionSheetTestId?: string;
  menuProps: Omit<DropdownMenuWithActionSheetProps, 'trigger'>;
  onClick?: (event: SyntheticEvent) => void;
  onMenuChange?: (isExpanded: boolean) => void;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  title: string;
  className?: ComponentProps<'div'>['className'];
  trigger: JSX.Element;
};

export const CardEllipsisMenu = ({
  actionSheetTestId,
  menuProps,
  onMenuChange = noop,
  title,
  className,
  trigger,
}: CardEllipsisMenuProps) => (
  <div className={className}>
    <DropdownMenuWithActionSheet
      data-testid={actionSheetTestId}
      isTitleHidden
      onChange={onMenuChange}
      title={title}
      trigger={trigger}
      {...menuProps}
    />
  </div>
);
