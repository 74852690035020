import Link from 'next/link';
import { memo } from 'react';

import { Routes } from '~/constants/routes';

export const AnonymousSignUpTermsInfo = memo(() => (
  <p className="text-12 text-pigeon-500">
    By clicking Submit, you agree to Air&apos;s{' '}
    <Link href={Routes.marketing.terms} target="_blank" className="text-pigeon-500 underline">
      Terms of Service
    </Link>{' '}
    and{' '}
    <Link href={Routes.marketing.privacy} target="_blank" className="text-pigeon-500 underline">
      Privacy Policy
    </Link>
  </p>
));

AnonymousSignUpTermsInfo.displayName = 'AnonymousSignUpTermsInfo';
