import { FormikField } from '@air/formik-field';
import { Input } from '@air/primitive-input';
import { getRegistrationValidationSchema } from '@air/utils-auth';
import { Formik, FormikProps } from 'formik';
import { ReactNode } from 'react';
import { InferType } from 'yup';

import Form from '~/components/Form';
import PasswordInput from '~/components/Form/PasswordInput';
import {
  SIGN_UP_EMAIL_INPUT,
  SIGN_UP_FIRST_NAME_INPUT,
  SIGN_UP_LAST_NAME_INPUT,
  SIGN_UP_PASSWORD_INPUT,
} from '~/constants/testIDs';
import { PASSWORD_REQS_STRING } from '~/utils/Auth';

import { TermsCheckbox } from './TermsCheckbox';

const _SignUpValidationSchema = getRegistrationValidationSchema();
export type SignUpUserType = InferType<typeof _SignUpValidationSchema>;

export interface EmailSignUpFormProps {
  defaultEmail: string | undefined;
  formID: string;
  onSubmit: (user: SignUpUserType) => void;
  disabled?: boolean;
  termsCheckbox?: ReactNode;
  initialValues?: Partial<SignUpUserType>;
  children?: (formikProps: FormikProps<SignUpUserType>) => ReactNode;
}

export const EmailSignUpForm = ({
  defaultEmail,
  formID,
  onSubmit,
  disabled,
  initialValues,
  termsCheckbox = <TermsCheckbox />,
  children,
}: EmailSignUpFormProps) => {
  const validationSchema = getRegistrationValidationSchema(defaultEmail);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...validationSchema.cast({}),
        ...initialValues,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount={true}
    >
      {(formikProps) => (
        <Form id={formID}>
          <FormikField
            id="email"
            className="mb-4"
            component={
              <Input
                type="email"
                placeholder="olivia@companyname.com"
                autoComplete="username"
                size="large"
                data-testid={SIGN_UP_EMAIL_INPUT}
                disabled={disabled}
              />
            }
            label="Work email *"
            name="email"
          />
          <div className="mb-4 flex gap-2">
            <FormikField
              id="firstName"
              name="firstName"
              label="First name"
              className="flex-1"
              component={
                <Input
                  type="text"
                  autoFocus
                  autoComplete="given-name"
                  size="large"
                  data-testid={SIGN_UP_FIRST_NAME_INPUT}
                  disabled={disabled}
                />
              }
            />

            <FormikField
              id="lastName"
              name="lastName"
              label="Last name"
              className="flex-1"
              component={
                <Input
                  type="text"
                  autoComplete="family-name"
                  size="large"
                  data-testid={SIGN_UP_LAST_NAME_INPUT}
                  disabled={disabled}
                />
              }
            />
          </div>

          <PasswordInput
            description={PASSWORD_REQS_STRING}
            size="large"
            className="mb-4"
            testId={SIGN_UP_PASSWORD_INPUT}
            disabled={disabled}
          />
          {children?.(formikProps)}
          {termsCheckbox}
        </Form>
      )}
    </Formik>
  );
};
