import { Upload } from '@air/redux-uploader';
import classNames from 'classnames';
import { memo } from 'react';

import FileExtensionPreview from '~/components/FileExtensionPreview';
import { AssetGalleryCardMetaDetails } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardMetaDetails';
import { useUploadingData } from '~/components/Upload/hooks/useUploadingData';
import { UPLOADING_ASSET } from '~/constants/testIDs';
import { useHover } from '~/hooks/useHover';
import { isTitleAndMetadataVisibleSelector } from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface UploadingAssetGalleryCardProps {
  upload: Upload;
  width: number;
  getClassName?: (props: { isHovering: boolean }) => string;
}

export const UploadingAssetGalleryCard = memo(({ upload, width, getClassName }: UploadingAssetGalleryCardProps) => {
  const {
    apiUploadData: { ext, size, importedName },
  } = upload;
  const { stateText } = useUploadingData(upload);
  const isTitleAndMetadataVisible = useAirSelector(isTitleAndMetadataVisibleSelector);
  const { handleHover, handleUnhover, isHovering } = useHover();
  const title = importedName || 'Untitled';

  return (
    <div
      data-testid={UPLOADING_ASSET}
      data-asset-title={title}
      onMouseEnter={handleHover}
      onMouseMove={handleHover}
      onMouseLeave={handleUnhover}
      className="relative flex h-full flex-col"
    >
      <div
        className={classNames(
          'absolute cursor-default rounded border-2 border-transparent',
          isHovering && 'bg-pigeon-50',
          getClassName?.({ isHovering }),
        )}
        style={{
          inset: -6,
        }}
      />
      <div className="relative flex grow items-center justify-center">
        <div className="absolute inset-0 flex flex-col rounded bg-pigeon-100" />
        <div className="relative">
          <FileExtensionPreview ext={ext} text={stateText} color="gray" size="medium" className="grow justify-center" />
        </div>
        {!isTitleAndMetadataVisible && !!size && (
          <div
            className={classNames(
              'absolute bottom-0 left-0 right-0 rounded rounded-t-none bg-black/70 px-2 pb-2 pt-1',
              isHovering ? 'opacity-100' : 'opacity-0',
            )}
          >
            <AssetGalleryCardMetaDetails
              hasDarkBackground
              clip={{
                ext,
                size,
              }}
              title={title}
              /** Card width minus the left and right padding */
              width={width - 16}
            />
          </div>
        )}
      </div>
      {isTitleAndMetadataVisible && !!size && (
        <div className="relative">
          <AssetGalleryCardMetaDetails
            clip={{
              ext,
              size,
            }}
            title={title}
            /** Card width minus the left and right padding */
            width={width - 4}
          />
        </div>
      )}
    </div>
  );
});

UploadingAssetGalleryCard.displayName = 'UploadingAssetGalleryCard';
