import { Account } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { isAirrorInstance } from '~/swr-hooks/isAirrorInstance';

export const ACCOUNTS_SSO_OPTIONS = 'ACCOUNTS_SSO_OPTIONS';

export const getAccountsSSOOptionsKey = (email?: string) => [ACCOUNTS_SSO_OPTIONS, { email }];

export const useGetAccountsSSOOptions = ({ email, enabled = true }: { email?: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: getAccountsSSOOptionsKey(email),

    queryFn: async () => {
      const result = await Account.getAccountSSOOptionsV2({ email: email! });
      if (isAirrorInstance(result)) {
        throw result;
      }
      return result.data || [];
    },
    enabled: !!email && enabled,
  });
};
