import { Button } from '@air/primitive-button';
import { Form, Formik, FormikConfig } from 'formik';
import { memo, PropsWithChildren, useCallback, useState } from 'react';

import { AnonymousSignUpFooter } from '~/components/AnonymousSignUpForm/AnonymousSignUpFooter';
import { AnonymousSignUpInput } from '~/components/AnonymousSignUpForm/AnonymousSignUpInput';
import { AnonymousSignUpTermsInfo } from '~/components/AnonymousSignUpForm/AnonymousSignUpTermsInfo';
import {
  AnonymousLoginSchema,
  AnonymousSignUpFormType,
  AnonymousSignUpSchema,
} from '~/components/AnonymousSignUpForm/types';

const initialValues: AnonymousSignUpFormType = {
  email: '',
  name: '',
};

export interface AnonymousSignUpFormProps {
  title: string;
  description: string;
  onFullLoginClick: () => void;
  onCancelClick?: () => void;

  onSubmitEmail: (email: string) => Promise<{ sessionId: string } | undefined>;
  onSignUp: (email: string, name: string) => Promise<void>;
}

export type AnonymousSignUpStep = 'login' | 'signUp';

const validators: Record<AnonymousSignUpStep, typeof AnonymousSignUpSchema | typeof AnonymousLoginSchema> = {
  login: AnonymousLoginSchema,
  signUp: AnonymousSignUpSchema,
};

export const AnonymousSignUpForm = memo(
  ({
    title,
    description,
    onSignUp,
    onSubmitEmail,
    onFullLoginClick,
    onCancelClick,
    children,
  }: PropsWithChildren<AnonymousSignUpFormProps>) => {
    const [signupStep, setSignupStep] = useState<AnonymousSignUpStep>('login');

    const _onSubmit = useCallback<FormikConfig<AnonymousSignUpFormType>['onSubmit']>(
      async ({ email, name }) => {
        if (signupStep === 'signUp') {
          await onSignUp(email, name);
        } else {
          try {
            const account = await onSubmitEmail(email);
            if (!account) {
              setSignupStep('signUp');
            }
          } catch (_error) {
            // error thrown by onSubmitEmail - handled on top level
          }
        }
      },
      [onSignUp, onSubmitEmail, signupStep],
    );

    return (
      <div data-testid="ANONYMOUS_SIGN_UP_FORM" className="flex flex-col gap-4 px-8 pb-4 pt-6">
        <p className="text-24 font-semibold text-pigeon-700">{title}</p>
        <p className="text-16 text-pigeon-700">{description}</p>

        <Formik
          validateOnMount={true}
          initialValues={initialValues}
          onSubmit={_onSubmit}
          validationSchema={validators[signupStep]}
        >
          {({ isSubmitting, isValid, errors, touched, values }) => (
            <Form className="flex flex-col gap-5">
              <AnonymousSignUpInput name="email" label="Email" error={touched.email ? errors.email : undefined} />

              {signupStep === 'signUp' && (
                <>
                  <AnonymousSignUpInput name="name" label="Name" error={touched.name ? errors.name : undefined} />
                  <AnonymousSignUpTermsInfo />
                </>
              )}
              {children}

              <AnonymousSignUpFooter onCancelClick={onCancelClick} onFullLoginClick={onFullLoginClick} className="mt-4">
                {signupStep === 'signUp' ? (
                  <Button
                    data-testid="ANONYMOUS_SIGN_UP_SUBMIT_BUTTON"
                    type="submit"
                    appearance="filled"
                    color="blue"
                    size="large"
                    isLoading={isSubmitting}
                    disabled={!isValid || !values.name}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    data-testid="ANONYMOUS_SIGN_UP_CONTINUE_BUTTON"
                    type="submit"
                    appearance="filled"
                    color="blue"
                    size="large"
                    isLoading={isSubmitting}
                    disabled={!isValid}
                  >
                    Continue
                  </Button>
                )}
              </AnonymousSignUpFooter>
            </Form>
          )}
        </Formik>
      </div>
    );
  },
);

AnonymousSignUpForm.displayName = 'AnonymousSignUpForm';
