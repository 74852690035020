import { Upload, uploadProgressSelector, UploadStatus } from '@air/redux-uploader';
import { getPercentage } from '@air/upload-utils';
import { useMemo } from 'react';

import { useAirSelector } from '~/utils/ReduxUtils';

/**
 * @property {boolean} animating true if item should show animation
 * @property {string} stateText 'Processing' or 'Uploading ・ progress%'
 */
interface UploadingData {
  animating: boolean;
  stateText: string;
}

/**
 * THis hook returns correct text for uploading item (Processing or Uploading x%) and information if animation should be visible
 *
 * @param upload {@link Upload}
 * @returns {@link UploadingData} uploading data
 */
export function useUploadingData(upload: Upload): UploadingData {
  const uploadProgress = useAirSelector((state) => uploadProgressSelector(state, upload.id));
  const progress = useMemo(() => {
    const progress = getPercentage(uploadProgress.progress, upload.file.size);

    if (isNaN(progress)) {
      return 0;
    }

    /**
     * We don't ever want to show 100% in the UI
     * @see https://air-labs-team.slack.com/archives/C018C7U0DQU/p1670346858744759?thread_ts=1670344295.770879&cid=C018C7U0DQU
     */
    if (progress === 100) {
      return 99;
    }

    return progress;
  }, [upload.file.size, uploadProgress.progress]);

  const animating = upload.status === UploadStatus.uploading;

  const stateText = useMemo(() => {
    if (upload.status === UploadStatus.completed) {
      return 'Processing…';
    } else if (upload.status === UploadStatus.paused) {
      return `Paused ・ ${progress}%`;
    } else {
      return `Uploading ・ ${progress}%`;
    }
  }, [upload.status, progress]);

  return { animating, stateText };
}
