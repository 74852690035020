import Link from 'next/link';
import { memo } from 'react';

import { Routes } from '~/constants/routes';

export const SignupSSOTermsInfo = memo(() => (
  <p className="text-center text-12 text-pigeon-400">
    By signing up with SSO or email, you agree to Air&apos;s{' '}
    <Link href={Routes.marketing.terms} target="_blank" className="text-pigeon-400 underline">
      Terms of Service
    </Link>{' '}
    ,{' '}
    <Link href={Routes.marketing.privacy} target="_blank" className="text-pigeon-400 underline">
      Privacy Policy
    </Link>
    , and occasional email updates.
  </p>
));

SignupSSOTermsInfo.displayName = 'SignupSSOTermsInfo';
