import { FocusEvent, useCallback } from 'react';

/**
 * This hook calls onFocus when user focuses element e.g. using TAB, and onBlur when focus is out.
 * It returns onFocus and onBlur methods, which should be passed to onFocus and onBlur component props
 * Methods explanation: https://reactjs.org/docs/events.html#focus-events
 * @param onItemFocused - method to call when item is focused
 * @param onItemBlurred - method to call when item is unfocused
 */
export function useFocusable(
  onItemFocused: () => void,
  onItemBlurred: () => void,
): [(e: FocusEvent<HTMLDivElement>) => void, (e: FocusEvent<HTMLDivElement>) => void] {
  const onFocus = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      if (e.currentTarget === e.target) {
        onItemFocused();
      }
    },
    [onItemFocused],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      // @ts-ignore - .contains shows error for e.relatedTarget
      if (!e.currentTarget.contains(e.relatedTarget)) {
        onItemBlurred();
      }
    },
    [onItemBlurred],
  );

  return [onFocus, onBlur];
}
