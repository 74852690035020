import { tailwindMerge } from '@air/tailwind-variants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ComponentProps, memo } from 'react';

import { CONTINUE_WITH_SAML_SSO_BTN } from '~/constants/testIDs';

type ContinueWithSSOButtonProps = ComponentProps<'button'>;

export const ContinueWithSAMLSSOButton = memo(({ className, ...rest }: ContinueWithSSOButtonProps) => {
  const { maintenanceModeJson } = useFlags();

  if (!!maintenanceModeJson?.maintenanceMessage) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-center">
      <button
        data-testid={CONTINUE_WITH_SAML_SSO_BTN}
        className={tailwindMerge('text-14 font-medium text-grey-10 underline hover:text-grey-12', className)}
        {...rest}
      >
        Continue with SAML SSO
      </button>
    </div>
  );
});

ContinueWithSAMLSSOButton.displayName = 'ContinueWithSSOButton';
