import { TrackSelectionTrigger } from '@air/analytics';
import {
  hasSelectedItemsSelector,
  itemIsSelectedSelector,
  preSelectItemsAction,
  resetSelectedItemsAction,
  showShiftSelectHighlightsSelector,
} from '@air/redux-selected-items';
import { Upload } from '@air/redux-uploader';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';

import { GalleryItemType } from '~/components/Gallery/types';
import { useHover, UseHoverType } from '~/hooks/useHover';
import { setShiftSelectHighlightedGalleryItemsAction } from '~/store/selectedItems/actions';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

import { useSelectShiftSelectedHighlightedItems } from './useSelectShiftSelectedHighlightedItems';
import { useToggleSelectedItem } from './useToggleSelectedItem';

/**
 * @property {(trigger: TrackSelectionTrigger, reset?: boolean) => void} handleSelect method to call to mark item as selected. If reset=true, all selected items will be cleared
 */
export interface UseMultiselectItemsType extends UseHoverType {
  handleSelect: (params: { trigger: TrackSelectionTrigger; reset?: boolean }) => void;
  handlePreSelect: (params: { trigger: TrackSelectionTrigger; reset?: boolean }) => void;
  handleUnselect: (params: { trigger: TrackSelectionTrigger }) => void;
}

/**
 * This hook returns info about hovering state and useful method to handle hovering and selecting
 *
 * @param id item's id
 * @param type item's type (board, asset or file)
 * @param data item's data (board object for board, id is enough for other types)
 * @returns {@link UseMultiselectItemsType}
 */
export function useMultiselectItem(
  id: SelectableGalleryItem['id'],
  type: SelectableGalleryItem['type'] | GalleryItemType.upload,
  data: SelectableGalleryItem['item'] | Upload,
): UseMultiselectItemsType {
  const dispatch = useDispatch();
  const store = useAirStore();
  const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, id));
  const hasSelectedItems = hasSelectedItemsSelector(store.getState());
  const multiselectHighlightIsShowing = showShiftSelectHighlightsSelector(store.getState());
  const { handleHover, handleUnhover, isHovering } = useHover();
  const { toggleSelectedItem } = useToggleSelectedItem();
  const { selectShiftSelectedHighlightedItems } = useSelectShiftSelectedHighlightedItems();

  const item = useMemo(
    () =>
      ({
        type,
        id,
        item: data ? data : { id },
      }) as SelectableGalleryItem,
    [data, id, type],
  );

  useEffect(() => {
    if (hasSelectedItems && isHovering) {
      dispatch(setShiftSelectHighlightedGalleryItemsAction({ item }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovering, dispatch, item]);

  const handleSelect = useCallback(
    ({ trigger, reset }: { trigger: TrackSelectionTrigger; reset?: boolean }) => {
      if (multiselectHighlightIsShowing) {
        selectShiftSelectedHighlightedItems(item, trigger);
      } else if (reset) {
        batch(() => {
          dispatch(resetSelectedItemsAction());
          toggleSelectedItem({ item, trigger });
        });
      } else {
        toggleSelectedItem({ item, trigger });
      }
    },
    [multiselectHighlightIsShowing, selectShiftSelectedHighlightedItems, item, dispatch, toggleSelectedItem],
  );

  const handlePreSelect = useCallback(
    ({ reset }: { trigger: TrackSelectionTrigger; reset?: boolean }) => {
      if (!isSelected) {
        batch(() => {
          reset && dispatch(resetSelectedItemsAction());
          dispatch(preSelectItemsAction({ itemsIds: [item.id] }));
        });
      }
    },
    [dispatch, isSelected, item.id],
  );

  const handleUnselect = useCallback(
    ({ trigger }: { trigger: TrackSelectionTrigger }) => {
      toggleSelectedItem({ item, trigger, forceToggle: 'unselect' });
    },
    [item, toggleSelectedItem],
  );

  if (type === GalleryItemType.upload) {
    return {
      isHovering,
      handleHover,
      handleUnhover,
      handlePreSelect: noop,
      handleSelect: noop,
      handleUnselect: noop,
    };
  }

  return { isHovering, handleHover, handleUnhover, handleSelect, handleUnselect, handlePreSelect };
}
