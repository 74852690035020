import { GallerySectionAddition } from '@air/component-gallery-view';
import { useCallback, useMemo } from 'react';

import LoadMoreSpinner from '~/components/LoadMoreSpinner';
import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';

export const useGallerySectionFooter = ({
  loadMore,
  isLoading,
  containerWidth,
  hasMore,
}: {
  containerWidth: number;
  hasMore: boolean;
  isLoading: boolean;
  loadMore: (() => void) | undefined;
}): GallerySectionAddition<GalleryMetadata> | undefined => {
  const loaderBox = useMemo(
    () =>
      hasMore || isLoading
        ? {
            aspectRatio: 0,
            left: 0,
            top: 0,
            width: containerWidth,
            height: 104,
          }
        : null,
    [containerWidth, hasMore, isLoading],
  );

  const renderLoader = useCallback(
    (_: number, isVisible: boolean) => {
      return loadMore ? (
        <LoadMoreSpinner key="loader" isVisible={isVisible} isLoading={isLoading} loadMore={loadMore} />
      ) : null;
    },
    [isLoading, loadMore],
  );

  if (!loaderBox) {
    return;
  }

  return { box: loaderBox, render: renderLoader };
};
