import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import classNames from 'classnames';

export interface RotatingDowntickProps {
  rotate: boolean;
}

/**
 * This is used in the side boards tree to open and close boards lists
 */
export const RotatingDowntick = ({ rotate }: RotatingDowntickProps) => {
  return (
    <TriangleDownIcon
      className={classNames(
        'flex h-4 w-4 items-center justify-center text-pigeon-400 transition-transform',
        rotate && '-rotate-90',
      )}
    />
  );
};
