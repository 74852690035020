import { ActivatedAccount } from '@air/analytics';
import { Account } from '@air/api/types';
import { memo, useState } from 'react';

import { SignUpModalSSOOrEmailForm } from '~/components/Modals/SignupModal/SignUpModalSSOOrEmailForm';
import { SignUpModalUserDetailsForm } from '~/components/Modals/SignupModal/SignUpModalUserDetailsForm';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';

interface SignUpModalFormProps {
  onSuccess: (account: Account) => void;
  accountCreationSource: ActivatedAccount['creationSource'];
  onLoginClick: () => void;
  closeModal: () => void;
  ssoCallbackData: SSOCallbackData | undefined;
}

export const SignUpModalForm = memo(
  ({ accountCreationSource, onSuccess, onLoginClick, ssoCallbackData, closeModal }: SignUpModalFormProps) => {
    const [submittedEmail, setSubmittedEmail] = useState<string | undefined>();

    if (submittedEmail) {
      return (
        <SignUpModalUserDetailsForm
          email={submittedEmail}
          accountCreationSource={accountCreationSource}
          onSuccess={onSuccess}
          onBackClick={() => setSubmittedEmail(undefined)}
        />
      );
    } else {
      return (
        <SignUpModalSSOOrEmailForm
          onLoginClick={onLoginClick}
          closeModal={closeModal}
          onEmailSubmit={setSubmittedEmail}
          accountCreationSource={accountCreationSource}
          ssoCallbackData={ssoCallbackData}
        />
      );
    }
  },
);

SignUpModalForm.displayName = 'SignUpModalForm';
