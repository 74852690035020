import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { getLoginValidationSchema } from '@air/utils-auth';
import { Formik, FormikProps } from 'formik';
import { ReactNode } from 'react';
import { InferType } from 'yup';

import Form from '~/components/Form';
import PasswordInput from '~/components/Form/PasswordInput';
import { LOGIN_EMAIL_INPUT, LOGIN_PASSWORD_INPUT, LOGIN_SUBMIT_BUTTON } from '~/constants/testIDs';
import { PASSWORD_REQS_STRING } from '~/utils/Auth';
import { sanitizeEmail } from '~/utils/EmailUtils';

const _loginValidationSchema = getLoginValidationSchema();
type LogInFormType = InferType<typeof _loginValidationSchema>;

export interface LoginFormProps {
  /**
   * Method called when user clicks 'Sign in' button
   * @param username
   * @param password
   */
  loginUser: (username: string, password: string) => void;
  onError: (username: string, error: unknown) => void;
  disabled?: boolean;
  existingEmail?: string;
  children?: (formikProps: FormikProps<LogInFormType>) => ReactNode;
}

export const LoginForm = ({ loginUser, onError, disabled = false, existingEmail, children }: LoginFormProps) => {
  const LoginFormValidationSchema = getLoginValidationSchema(existingEmail);

  return (
    <Formik
      validateOnMount={true}
      initialValues={LoginFormValidationSchema.cast({})}
      validationSchema={LoginFormValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const emailValue = sanitizeEmail(values.email);

        try {
          setSubmitting(true);

          await loginUser(emailValue, values.password);
        } catch (error) {
          onError(emailValue, error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => (
        <Form>
          <FormikField
            className="mb-4"
            component={
              <Input
                type="email"
                autoComplete="username"
                data-testid={LOGIN_EMAIL_INPUT}
                disabled={disabled}
                defaultValue={existingEmail}
                size="large"
              />
            }
            label="Email *"
            name="email"
            id="email"
          />

          <PasswordInput
            description={PASSWORD_REQS_STRING}
            size="large"
            className="mb-4"
            testId={LOGIN_PASSWORD_INPUT}
            disabled={disabled}
          />

          {children ? (
            children(formikProps)
          ) : (
            <Button
              type="submit"
              className="mt-12 w-full"
              appearance="filled"
              color="blue"
              size="large"
              isLoading={formikProps.isSubmitting}
              data-testid={LOGIN_SUBMIT_BUTTON}
              disabled={disabled || formikProps.isSubmitting}
            >
              Log in
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};
