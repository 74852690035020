import { Modal } from '@air/primitive-modal';
import { useCallback } from 'react';

import { SamlSSOLoginForm, SamlSSOLoginFormProps } from '~/components/Auth/SamlSSOLoginForm';
import { AirModalLogoHeader } from '~/components/Modals/AirModalLogoHeader';
import { saveSSOCallbackData, SSOCallbackData } from '~/components/PublicSSOCallback/utils';

export interface SamlSSOLoginModalProps extends Pick<SamlSSOLoginFormProps, 'customState'> {
  onShowLoginClick: () => void;
  ssoCallbackData?: SSOCallbackData;
}

export const SamlSSOLoginModal = ({
  onShowLoginClick,
  onClose,
  ssoCallbackData,
  customState,
}: AirModalProps<SamlSSOLoginModalProps>) => {
  const saveRedirectData = useCallback(() => {
    // save data about action to perform when user signs in using SSO
    if (ssoCallbackData) {
      saveSSOCallbackData(ssoCallbackData);
    }
  }, [ssoCallbackData]);

  const showLogin = useCallback(() => {
    onClose();
    setTimeout(onShowLoginClick, 200);
  }, [onClose, onShowLoginClick]);

  return (
    <Modal className="w-[414px] p-8" isOpen onDismiss={onClose}>
      <AirModalLogoHeader onClose={onClose} />
      <h1 className="mb-4 text-center text-20 font-semibold text-pigeon-700">Log in with SAML SSO</h1>
      <SamlSSOLoginForm
        customState={customState}
        onContinueWithPasswordClick={showLogin}
        onRedirect={saveRedirectData}
      />
    </Modal>
  );
};
