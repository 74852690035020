import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { itemIsSelectedSelector, selectedItemsCountSelector } from '@air/redux-selected-items';

import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

export interface UseSelectionAwareContextMenuParams {
  contextMenuOptions: DropdownMenuOption[];
  id: string;
  selectionMenuOptions: DropdownMenuOption[];
}

export function useSelectionAwareContextMenuOptions({
  id,
  contextMenuOptions,
  selectionMenuOptions,
}: UseSelectionAwareContextMenuParams) {
  const store = useAirStore();
  const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, id));
  const selectedCount = selectedItemsCountSelector(store.getState());

  return {
    options: selectedCount > 1 && isSelected ? selectionMenuOptions : contextMenuOptions,
  };
}
