import { Meta } from '../types/docs';

const META: Meta = {
  name: 'logged_in_to_anonymous_account',
  description: 'User logged in to previously created anonymous account',
  params: [
    {
      name: 'location',
      description: 'Location of the event',
      required: true,
      type: 'enum',
      values: ['clip-share-link', 'board-share-link', 'content-collection', 'custom-field-change', 'comment-addition'],
    },
    {
      name: 'object_id',
      description: 'Id of an object that share link is generated for',
      required: true,
      type: 'string',
    },
    {
      name: 'object_type',
      description: 'Type of an object that share link is generated for',
      required: true,
      type: 'enum',
      values: ['board', 'clip'],
    },
    {
      name: 'share_url',
      description: 'Generated share url',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
