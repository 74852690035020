import { memo } from 'react';
import isEqual from 'react-fast-compare';

import { GalleryItem } from './types';

export interface GalleryViewBoxProps extends GalleryItem {
  isVisible: boolean;
}

export const GalleryViewBox = memo(({ box, render, index, isVisible }: GalleryViewBoxProps) => {
  return (
    <div
      className="[&>*]:h-full"
      style={{
        width: box.width,
        height: box.height,
        left: box.left,
        position: 'absolute',
      }}
    >
      {render(index, isVisible)}
    </div>
  );
}, isEqual);

GalleryViewBox.displayName = 'GalleryViewBox';
