import { BaseCustomField } from '@air/api/types';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useGetPublicCustomFields } from '~/swr-hooks/customFields/useGetPublicCustomFields';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface PublicCustomFieldsProviderProps extends PropsWithChildren<object> {
  canViewCustomFields: boolean;
}

export interface PublicCustomFieldsProviderContextValue {
  customFields: BaseCustomField[] | undefined;
  error?: unknown;
}

const defaultValue: PublicCustomFieldsProviderContextValue = {
  customFields: undefined,
};

const PublicCustomFieldsProviderContext = createContext<PublicCustomFieldsProviderContextValue>(defaultValue);

export const PublicCustomFieldsProvider = ({ children, canViewCustomFields }: PublicCustomFieldsProviderProps) => {
  const { data: customFields, error } = useGetPublicCustomFields(canViewCustomFields);

  const value = useMemo(() => ({ customFields: customFields?.data, error }), [customFields?.data, error]);

  return (
    <PublicCustomFieldsProviderContext.Provider value={value}>{children}</PublicCustomFieldsProviderContext.Provider>
  );
};

export const usePublicCustomFieldsContext = () => {
  const context = useContext(PublicCustomFieldsProviderContext);

  if (context === defaultValue) {
    const error = 'PublicCustomFieldsProviderContext used outside of PublicCustomFieldsProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
