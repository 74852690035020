import type { GalleryItem, GallerySection } from '../types';
import { boxesToRows } from './boxesToRows';

export function sectionsToRows<T = object>(sections: GallerySection<T>[]) {
  return sections.reduce((acc, section) => {
    if (section.header) {
      acc.push([{ ...section.header, index: 0 }]);
    }

    if (section.items) {
      const itemRows = boxesToRows(section.items.boxes).map((items) =>
        items.map((item) => ({
          ...item,
          ...section.items,
        })),
      );
      acc.push(...itemRows);
    }

    if (section.footer) {
      acc.push([{ ...section.footer, index: 0 }]);
    }

    return acc;
  }, [] as GalleryItem<T>[][]);
}
