import { memo } from 'react';

import { SignupSSOTermsInfo } from '~/components/Modals/SignupModal/SignupSSOTermsInfo';

interface SignUpModalFooterProps {
  onLoginClick: () => void;
}

export const SignUpModalFooter = memo(({ onLoginClick }: SignUpModalFooterProps) => {
  return (
    <div className="flex flex-col gap-6">
      <SignupSSOTermsInfo />
      <div className="flex flex-col items-center justify-center">
        <p className="text-center text-14 text-pigeon-600">Already have an account?</p>
        <button className="text-14 font-medium text-jay-500 hover:underline" onClick={onLoginClick}>
          Log in
        </button>
      </div>
    </div>
  );
});

SignUpModalFooter.displayName = 'SignUpModalFooter';
