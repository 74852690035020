import { FormikInput } from '@air/formik-input';
import { Label } from '@air/primitive-label';
import classNames from 'classnames';
import { memo } from 'react';

import { AnonymousSignUpFormType } from '~/components/AnonymousSignUpForm/types';

interface AnonymousSignUpInputProps {
  name: keyof AnonymousSignUpFormType;
  label: string;
  error: string | undefined;
}

export const AnonymousSignUpInput = memo(({ name, label, error }: AnonymousSignUpInputProps) => (
  <div className="relative flex flex-col gap-2">
    <Label htmlFor={`anonymous-signup-${name}`} size="medium">
      {label}*
    </Label>
    <FormikInput
      autoFocus={true}
      size="extra-large"
      className={classNames('w-full', error ? 'border-flamingo-600' : '')}
      id={`anonymous-signup-${name}`}
      name={name}
      data-testid="ANONYMOUS_SIGN_UP_INPUT"
      data-id={name}
    />
    {!!error && (
      <div
        data-testid="ANONYMOUS_SIGN_UP_ERROR"
        data-id={name}
        className="absolute top-full w-full translate-y-0.5 text-12 text-flamingo-600"
      >
        {error}
      </div>
    )}
  </div>
));

AnonymousSignUpInput.displayName = 'AnonymousSignUpInput';
