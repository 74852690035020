import { createContext, memo, ReactNode, useContext, useMemo } from 'react';

type ShortIdProviderContextValue =
  | {
      shortId: string;
      objectType: 'clip' | 'board';
      objectId: string;
    }
  | undefined;

const ShortIdProviderContext = createContext<ShortIdProviderContextValue>(undefined);

export type ShortIdProviderProps = ShortIdProviderContextValue & {
  children: ReactNode;
};

export const ShortIdProvider = memo(({ children, shortId, objectType, objectId }: ShortIdProviderProps) => {
  const value = useMemo(
    () => ({
      shortId,
      objectType,
      objectId,
    }),
    [objectId, objectType, shortId],
  );

  return <ShortIdProviderContext.Provider value={value}>{children}</ShortIdProviderContext.Provider>;
});

ShortIdProvider.displayName = 'ShortIdProvider';

export function useShortIdContext() {
  const context = useContext(ShortIdProviderContext);

  if (!context) {
    throw 'ShortIdProviderContext used outside of ShortIdProvider';
  }

  return context;
}
