import { useEffect, useRef } from 'react';

export interface UseLoadMoreLogicParams {
  /** Function called when isLoading is false and isVisible is true */
  loadMore: () => void;
  /** true if request is in progress */
  isLoading: boolean;
  /** true if component is visible to a user */
  isVisible: boolean;
}

/**
 * This hook is used to load more data when a component becomes visible.
 * It calls loadMore function only once when the component is mounted.
 */
export const useLoadMoreLogic = ({ loadMore, isVisible, isLoading }: UseLoadMoreLogicParams) => {
  const isLoadMoreCalled = useRef(false);

  useEffect(() => {
    if (!isLoadMoreCalled.current && !isLoading && isVisible) {
      isLoadMoreCalled.current = true;
      loadMore();
    }
  }, [isLoading, isVisible, loadMore]);
};
