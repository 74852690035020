import * as Yup from 'yup';

const emailValidation = Yup.string()
  .email('Please enter a valid email address')
  .required('Please enter a valid email address');
const nameValidation = Yup.string().required('Name is required');

export const AnonymousLoginSchema = Yup.object()
  .shape({
    email: emailValidation,
  })
  .required();

export const AnonymousSignUpSchema = Yup.object()
  .shape({
    email: emailValidation,
    name: nameValidation,
  })
  .required();

export type AnonymousSignUpFormType = Yup.InferType<typeof AnonymousSignUpSchema>;
