import { GalleryItem } from '../types';

export function calculateListRowHeight<T extends GalleryItem>({ index, rows }: { index: number; rows: T[][] }) {
  if (!rows.length) {
    return 0;
  }

  if (!rows[index]) {
    // when a row isn't loaded, it will be undefined, so we use the first row's height as a placeholder
    return rows[0][0].box.height + (rows[0][0].verticalItemsSpacing ?? 0);
  }

  return rows[index][0].box.height + (rows[index][0].verticalItemsSpacing ?? 0); // adds vertical spacing between rows
}
