import { useBreakpointsContext } from '@air/provider-media-query';
import layoutGeometry from 'justified-layout';
import { defaultsDeep } from 'lodash';
import { useMemo } from 'react';

interface JustifiedGalleryBase {
  containerWidth: number;
  ratios: number[];
  options?: {
    boxSpacing?: {
      vertical?: number;
      horizontal?: number;
    };
    containerPadding?: {
      top?: number;
      bottom?: number;
      left?: number;
      right?: number;
    };
    targetRowHeight?: number;
    additionalRowHeight?: number;
  };
}

export const useJustifiedGrid = ({ containerWidth = 100, ratios = [], options = {} }: JustifiedGalleryBase) => {
  const { isAboveSmallScreen } = useBreakpointsContext();

  const horizontalContainerPadding = 0;
  const verticalBoxSpacing = !isAboveSmallScreen ? 8 : 16;
  const horizontalBoxSpacing = !isAboveSmallScreen ? 8 : 16;

  const otherOptions = useMemo(
    () =>
      defaultsDeep(options, {
        boxSpacing: {
          horizontal: horizontalBoxSpacing,
          vertical: verticalBoxSpacing,
        },
        containerPadding: {
          bottom: 0,
          right: horizontalContainerPadding,
          left: horizontalContainerPadding,
        },
        targetRowHeight: 160,
        showWidows: true,
      }),
    [horizontalBoxSpacing, horizontalContainerPadding, options, verticalBoxSpacing],
  );

  const geometry = useMemo(
    () =>
      layoutGeometry(ratios, {
        containerWidth,
        ...otherOptions,
      }),
    [containerWidth, otherOptions, ratios],
  );

  const { boxes, containerHeight } = geometry;

  return {
    boxes: !!otherOptions.additionalRowHeight
      ? boxes.map((box) => ({ ...box, height: box.height + otherOptions.additionalRowHeight }))
      : boxes,
    containerHeight,
  };
};
