import { Clip } from '@air/api/types';
import { memo } from 'react';

import ShimmerBackground from '~/assets/gradient.png';
import FileExtensionPreview from '~/components/FileExtensionPreview';

type AssetGalleryCardProcessingProps = Pick<Clip, 'ext'>;

export const AssetGalleryCardProcessing = memo(({ ext }: AssetGalleryCardProcessingProps) => {
  return (
    <div
      className="relative flex flex-1 flex-col items-center justify-center bg-pigeon-100"
      style={{
        backgroundSize: '400% 400%',
        backgroundImage: `url(${ShimmerBackground.src})`,
        animation: 'new-shimmer 2s infinite cubic-bezier(0.14, 0.38, 0.89, 0.65)',
      }}
    >
      <FileExtensionPreview ext={ext} text="Processing" color="gray" size="medium" className="grow justify-center" />
    </div>
  );
});

AssetGalleryCardProcessing.displayName = 'AssetGalleryCardProcessing';
