import { TrackSelectionTrigger, useTrackDeselectedItems, useTrackSelectedItems } from '@air/analytics';
import { itemIsSelectedSelector, selectedItemsSelector, unselectItemAction } from '@air/redux-selected-items';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getTrackSelectedItemsCountsAndType } from '~/analytics/utils';
import { selectGalleryItemAction } from '~/store/selectedItems/actions';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { useAirStore } from '~/utils/ReduxUtils';

export function useToggleSelectedItem() {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { trackDeselectedItems } = useTrackDeselectedItems();
  const { trackSelectedItems } = useTrackSelectedItems();

  const toggleSelectedItem = useCallback(
    ({
      item,
      trigger,
      forceToggle,
    }: {
      item: SelectableGalleryItem;
      trigger: TrackSelectionTrigger;
      forceToggle?: 'select' | 'unselect';
    }) => {
      const itemIsSelected = itemIsSelectedSelector(store.getState(), item.id);
      const selectedItems = selectedItemsSelector(store.getState());
      if (itemIsSelected || (forceToggle && forceToggle !== 'select')) {
        const nextSelectedItems = selectedItems.filter((i) => i.id !== item.id);
        const trackingParams = getTrackSelectedItemsCountsAndType(nextSelectedItems);
        trackDeselectedItems({ ...trackingParams, trigger });
        dispatch(unselectItemAction({ itemId: item.id }));
      } else {
        const nextSelectedItems = [...selectedItems, item];
        const trackingParams = getTrackSelectedItemsCountsAndType(nextSelectedItems);
        trackSelectedItems({ ...trackingParams, trigger });
        dispatch(selectGalleryItemAction({ item }));
      }
    },
    [dispatch, store, trackDeselectedItems, trackSelectedItems],
  );

  return { toggleSelectedItem };
}
