import { Clip, ClipStatus } from '@air/api/types';
import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, memo, useMemo, useState } from 'react';
import { BackgroundProps } from 'react-imgix';

import { AssetGalleryCardFailedTranscode } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardFailedTranscode';
import { AssetGalleryCardProcessing } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardProcessing';
import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';
import { getRoundedImgixDimensions } from '~/utils/getRoundedImgixDimensions';

interface AssetGalleryCardThumbnailImageProps
  extends Pick<Clip, 'status' | 'ext' | 'description'>,
    Pick<ComponentProps<'img'>, 'className'> {
  /** URI for the image to be displayed in the clip thumbnail */
  src: string | undefined;
  /** URI for the animated image to be displayed on hover for a clip e.g an animated preview on video hover */
  animatedSrc?: string;
  /** width of the image that should be rendered in the gallery */
  width: number;
  /** height of the image that should be rendered in the gallery */
  height: number;
  /**
   * Imigix parameters
   */
  imgixParams?: BackgroundProps['imgixParams'];
}

export const AssetGalleryCardThumbnailImage = memo(
  ({
    animatedSrc,
    className,
    description,
    ext,
    height,
    imgixParams,
    status,
    src,
    width,
  }: AssetGalleryCardThumbnailImageProps) => {
    const [isLoaded, setLoaded] = useState(false);

    const imgixDimensions = useMemo(() => getRoundedImgixDimensions({ width, height }), [height, width]);

    if ([ClipStatus.failed, ClipStatus.nonTranscodable].includes(status)) {
      return <AssetGalleryCardFailedTranscode ext={ext} />;
    }

    /**
     * If there is no src, we are still processing the clip. It can be present while clip is still in transcoding state for videos
     */
    if (!src) {
      return <AssetGalleryCardProcessing ext={ext} />;
    }

    const imageClassNames: React.ComponentProps<'img'>['className'] = tailwindMerge(
      'block min-h-full w-full rounded object-cover',
      isLoaded ? 'opacity-100' : 'opacity-0',
      className,
    );

    return animatedSrc ? (
      <img alt={description} src={animatedSrc} className={imageClassNames} />
    ) : (
      <ImgixImage
        alt={description || ''}
        fallbackUrl={getCloudFrontUrlFromImgixUrl(src)}
        src={src}
        imgixParams={{
          auto: 'compress',
          fit: 'crop',
          ...imgixParams,
        }}
        width={imgixDimensions.width}
        height={imgixDimensions.height}
        onLoad={() => setLoaded(true)}
        className={imageClassNames}
      />
    );
  },
);

AssetGalleryCardThumbnailImage.displayName = 'AssetGalleryCardThumbnailImage';
