import { ActivatedAccount, useTrackActivatedAccount, useTrackCompletedOnboardingBasicInfo } from '@air/analytics';
import { Account as AccountAPI, Auth as AuthAPI } from '@air/api';
import { Account } from '@air/api/types';
import { IDP_ACCOUNT_EXISTS, INVALID_EMAIL } from '@air/errors';
import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, useCallback } from 'react';

import { useJoinWorkspaceInfo } from '~/components/JoinWorkspace/hooks/useJoinWorkspaceInfo';
import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { getAccountKey } from '~/swr-hooks/account/useAccount';
import { useReferral } from '~/swr-hooks/referral/useReferral';
import { ExistingAccountMessageWithAuthLink, loginViaEmail } from '~/utils/Auth';
import { callOnFullAccountUserPool } from '~/utils/callOnFullAccountUserPool';
import { sanitizeEmail } from '~/utils/EmailUtils';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import { pushWithExistingQuery } from '~/utils/PathUtils';

export interface EmailSignUpParams {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };

  /**
   * Use this in conjunction with `status` in Formik props. You can render form-level errors with `status.formError`
   * within the Formik provider's render props.
   */
  setFormError: (error: ReactNode) => void;
  onRegistrationFailure: () => void;
  onRegistrationSuccess?: (account: Account) => void;
  onUnhandledException: (error: unknown) => void;
  creationSource: ActivatedAccount['creationSource'];
}

export const useSignUpWithEmail = () => {
  const queryClient = useQueryClient();
  const { trackCompletedOnboardingBasicInfo } = useTrackCompletedOnboardingBasicInfo();
  const { trackActivatedAccount } = useTrackActivatedAccount();
  const { getJoinWorkspaceInfo } = useJoinWorkspaceInfo();
  const { trackReferral } = useReferral();

  const signUpWithEmail = useCallback(
    async ({
      user,
      setFormError,
      onRegistrationFailure,
      onRegistrationSuccess,
      onUnhandledException,
      creationSource,
    }: EmailSignUpParams) =>
      callOnFullAccountUserPool(async () => {
        user.email = sanitizeEmail(user.email);

        trackCompletedOnboardingBasicInfo({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        });

        try {
          const cognitoUser = await AuthAPI.signUp({ email: user.email, password: user.password, platform: 'web' });

          const response = await loginViaEmail({ username: user.email, password: user.password, cognitoUser });

          const authId = response.userInfo?.sub as string;

          const account = await AccountAPI.create({
            id: authId,
            authId,
            firstName: user.firstName,
            lastName: user.lastName,
          });
          queryClient.setQueryData(getAccountKey(), account);

          const workspaceInfo = getJoinWorkspaceInfo();

          trackActivatedAccount({
            id: account.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            authProvider: 'Cognito',
            creationSource,
            workspaceId: workspaceInfo?.id,
            workspaceName: workspaceInfo?.name,
          });

          if (account?.id) {
            trackReferral({ accountId: account.id });
          }

          onRegistrationSuccess?.(account);
        } catch (_error) {
          const error = convertUnknownToError(_error);

          if (error.message.includes(INVALID_EMAIL.message)) {
            setFormError('Please enter a valid email address');
          } else if (error.message.includes(IDP_ACCOUNT_EXISTS.message)) {
            setFormError(
              <ExistingAccountMessageWithAuthLink
                rawErrorMessage={error.message}
                messagePrefix="This account already exists."
              />,
            );
          } else if (error.message.includes('UsernameExistsException')) {
            /**
             * We used to check if the email has already been registered when the user would blur off the email via
             * `useOnEmailBlur` but it would cause a race condition within the API package. Now, we just check it on
             * submission and let the server return an error.
             *
             * @see https://air-labs-team.slack.com/archives/CKE8SK3M1/p1612643929224000
             */
            pushWithExistingQuery({
              path: Routes.auth.login,
              newQuery: { [QueryParamNames.existingEmail]: user.email },
            });
          } else {
            onUnhandledException(error);
            setFormError('An unknown error has occurred. Please try again later.');
          }

          onRegistrationFailure();
        }
      }),
    [getJoinWorkspaceInfo, queryClient, trackActivatedAccount, trackCompletedOnboardingBasicInfo, trackReferral],
  );

  return {
    signUpWithEmail,
  };
};
